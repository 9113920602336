/*============================================

   Theme Name: SkillGro - Online Courses & Education Template
   Author : ThemeGenix
   Support: themegenix@gmail.com
   Description: SkillGro - Online Courses & Education Template
   Version: 1.0

==============================================*/
/* CSS Index
==============================================

    01. Theme Default CSS
    02. Header
    03. Mobile Menu
    04. Banner
    05. Slider
    06. Brand
    07. About
    08. Courses
    09. Categories
    10. Online
    11. Testimonial
    12. Instructor
    13. Mentor
    14. CTA
    15. Fact
    16. Events
    17. Quick Action
    18. FAQ
    19. Shop
    20. Blog
    21. Newsletter
    22. Contact
    23. 404 Error
    24. Footer

==============================================*/
/*============================================
/*  01. Theme Default CSS
==============================================*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&amp;family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&amp;display=swap");
:root {
  --tg-body-font-family: 'Inter', sans-serif;
  --tg-heading-font-family: 'Poppins', sans-serif;
  --tg-icon-font-family: "Font Awesome 5 Free";
  --tg-body-font-size: 16px;
  --tg-body-line-height: 1.75;
  --tg-heading-line-height: 1.3;
  --tg-body-color: #6D6C80;
  --tg-heading-color: #161439;
  --tg-theme-primarypurple: #5751E1;
  --tg-theme-primary: #3783F3;
  /* --tg-theme-secondary: #FFC224; */
  --tg-theme-secondary: #B3EC55;
  --tg-common-color-blue: #050071;
  --tg-common-color-blue-2: #282568;
  --tg-common-color-indigo: #9B51E0;
  --tg-common-color-purple: #8121FB;
  --tg-common-color-pink: #d63384;
  --tg-common-color-red: #E11B24;
  --tg-common-color-orange: #fd7e14;
  --tg-common-color-yellow: #F8BC24;
  --tg-common-color-yellow-2: #FBE67B;
  --tg-common-color-green: #12BB6A;
  --tg-common-color-teal: #219653;
  --tg-common-color-cyan: #0dcaf0;
  --tg-common-color-white: #fff;
  --tg-common-color-gray: #F7F7F9;
  --tg-common-color-gray-2: #EFEEFE;
  --tg-common-color-gray-3: #7F7E97;
  --tg-common-color-gray-4: #ACAACC;
  --tg-common-color-gray-5: #B2BBCC;
  --tg-common-color-dark: #1C1A4A;
  --tg-common-color-black: #06042E;
  --tg-common-color-black-2: #161439;
  --tg-common-color-black-3: #000;
  --tg-border-1: #C9C9DD;
  --tg-border-2: #D0DAE9;
  --tg-border-3: #E2E2E2;
  --tg-border-4: #D7DCE3;
  --tg-border-5: #2F466A;
  --tg-fw-extra-bold: 800;
  --tg-fw-bold: 700;
  --tg-fw-semi-bold: 600;
  --tg-fw-medium: 500;
  --tg-fw-regular: 400;
  --tg-fw-light: 300;
  --tg-common-color-lightgreen: #B3EC554D;
}

/*=============================
	Typography css start
===============================*/
body {
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
  color: var(--tg-body-color);
  line-height: var(--tg-body-line-height);
  padding-right: 0px !important;
}

img,
.img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a,
button {
  color: var(--tg-theme-primary);
  outline: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus,
.button:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

a:hover,
button:hover {
  color: var(--tg-theme-secondary);
  text-decoration: none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
  margin-top: 0px;
  font-weight: var(--tg-fw-semi-bold);
  line-height: var(--tg-heading-line-height);
  text-transform: unset;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.list-wrap {
  margin: 0px;
  padding: 0px;
}
.list-wrap li {
  list-style: none;
}

p {
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  line-height: var(--tg-body-line-height);
  font-weight: var(--tg-fw-regular);
  color: var(--tg-body-color);
  margin-bottom: 15px;
}

hr {
  border-bottom: 1px solid var(--tg-common-color-gray);
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

label {
  color: var(--tg-heading-color);
  cursor: pointer;
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  text-shadow: none;
}

::selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  text-shadow: none;
}

/*=============================
    - Input Placeholder
===============================*/
input,
textarea {
  color: var(--tg-body-color);
}

*::-moz-placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}

*::placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}

/*=============================
    - Common Classes
===============================*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

/*=============================
    - Bootstrap Custom
=============================*/
.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.gutter-20 {
  --bs-gutter-x: 20px;
}

.container {
  max-width: 1440px;
}

@media (max-width: 1500px) {
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 1199.98px) {
  .container {
    max-width: 960px;
  }
  
  .menuless1200 {
    background: #161439;
    position: absolute;
    right: 0;
    z-index: 4;
    top: 0px;
}

.menuless1200 ul{
  flex-direction: column !important;
  
} 
.menuless1200 ul li a{
  color: white !important;
  padding: 15px 10px !important;
}

.menuless1200 .closeBtn{
  display: block !important;
}
.menuless1200 .closeBtn a{
  display: flex;
  justify-content: right;
  padding-right: 20px !important;
}

}
@media (max-width: 991.98px) {
  .container {
    max-width: 720px;
  }
}
@media (max-width: 767.98px) {
  .container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
}

.custom-container {
  max-width: 1680px;
}
@media (max-width: 1800px) {
  .custom-container {
    max-width: 1680px;
  }
}
@media (max-width: 1500px) {
  .custom-container {
    max-width: 1320px;
  }
}
@media (max-width: 1199.98px) {
  .custom-container {
    max-width: 960px;
  }
}
@media (max-width: 991.98px) {
  .custom-container {
    max-width: 720px;
  }
}
@media (max-width: 767.98px) {
  .custom-container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/*=============================
	1. Button style
===============================*/
.tg-button-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.btn {
  user-select: none;
  -moz-user-select: none;
  background: var(--tg-theme-primary) none repeat scroll 0 0;
  border: medium none;
  color: var(--tg-common-color-white);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: var(--tg-fw-semi-bold);
  font-family: var(--tg-heading-font-family);
  letter-spacing: 0;
  line-height: 1.12;
  margin-bottom: 0;
  padding: 16px 30px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  vertical-align: middle;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  white-space: nowrap;
  box-shadow: 4px 6px 0px 0px var(--tg-theme-secondary);
  overflow: hidden;
}
.btn:hover, .btn:focus-visible {
  background: var(--tg-theme-secondary);
  color: var(--tg-heading-color);
  box-shadow: 0 0 0 0 var(--tg-common-color-blue);
}
.btn:hover img.injectable {
  filter: invert(0) !important;
}
.btn .text {
  display: block;
}
.btn.tg-svg {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn .svg-icon {
  width: 14px;
  display: block;
  margin-top: -3px;
}
.btn.btn-border {
  background: var(--tg-common-color-white);
  border: 1px solid var(--tg-theme-primary);
  color: var(--tg-theme-primary);
  padding: 19px 23px 16px 23px;
}
.btn.btn-border svg path {
  stroke: var(--tg-theme-primary);
}
.btn.btn-border:focus-visible, .btn.btn-border:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.btn.btn-border:focus-visible svg path, .btn.btn-border:hover svg path {
  stroke: var(--tg-common-color-white);
}
.btn.white-btn {
  background: var(--tg-common-color-white);
  color: var(--tg-theme-secondary);
}
.btn.white-btn svg path {
  stroke: var(--tg-theme-secondary);
}
.btn-two {
  background: var(--tg-theme-secondary);
  border: 2px solid var(--tg-common-color-black-3);
  -webkit-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -moz-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -ms-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -o-box-shadow: 4px 4px 0px 0px #3D3D3D;
  box-shadow: 4px 4px 0px 0px #3D3D3D;
  color: var(--tg-common-color-black-3);
}
.btn-two svg {
  color: var(--tg-common-color-black-3) !important;
}
.btn-two:hover, .btn-two:focus-visible {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  box-shadow: 0 0 0 0 var(--tg-common-color-blue);
  border-color: var(--tg-theme-primary);
}
.btn-two:hover svg, .btn-two:focus-visible svg {
  color: var(--tg-common-color-white) !important;
}

.arrow-btn {
  --arrow-hover-move-x: -110%;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s linear;
}
.arrow-btn svg {
  color: var(--tg-common-color-white);
  width: 15px;
  transition: all 0.3s ease-out 0s;
  transform: translateY(-1px);
}
.arrow-btn svg path {
  transition: transform 0.38s cubic-bezier(0.37, 0.08, 0.02, 0.93), opacity 0.18s ease-out;
}
.arrow-btn svg path:nth-of-type(1) {
  transform: translateX(0);
  opacity: 1;
  transition-delay: 0.15s, 0.15s;
}
.arrow-btn svg path:nth-of-type(2) {
  transform: translateX(calc(1 * var(--arrow-hover-move-x)));
  opacity: 0.5;
  transition-delay: 0s, 0s;
}
.arrow-btn:focus-visible svg, .arrow-btn:hover svg {
  color: var(--tg-heading-color);
}
.arrow-btn:focus-visible svg path:nth-of-type(1), .arrow-btn:hover svg path:nth-of-type(1) {
  transform: translateX(calc(-1 * var(--arrow-hover-move-x)));
  opacity: 0;
  transition-delay: 0s, 0s;
}
.arrow-btn:focus-visible svg path:nth-of-type(2), .arrow-btn:hover svg path:nth-of-type(2) {
  transform: translateX(0) translateY(0);
  opacity: 1;
  transition-delay: 0.15s, 0.15s;
}

/*=============================
    00. Preloader
===============================*/
#preloader {
  background-color: var(--tg-common-color-white);
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}
#preloader .loader .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 3px solid var(--tg-common-color-gray);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
#preloader .loader .loader-container::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-top: 3px solid var(--tg-theme-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  animation: loaderspin 1.8s infinite ease-in-out;
  -webkit-animation: loaderspin 1.8s infinite ease-in-out;
}
#preloader .loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80px;
  text-align: center;
}
#preloader .loader .loader-icon img {
  animation: loaderpulse alternate 900ms infinite;
  width: 40px;
}

@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*=============================
    - Background color
===============================*/
.grey-bg {
  background: var(--tg-common-color-gray);
}

.white-bg {
  background: var(--tg-common-color-white);
}

.black-bg {
  background: var(--tg-common-color-black);
}

/*=========================
04. Breadcrumb
===========================*/
.breadcrumb__bg {
  background-size: cover;
  background-position: center;
  padding: 107px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .breadcrumb__bg {
    padding: 100px 0;
  }
}
.breadcrumb__bg-two {
  padding: 61px 0;
}
.breadcrumb__bg-two .breadcrumb__shape-wrap img:nth-child(3) {
  display: none;
}
.breadcrumb__content .title {
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 1.2;
}
@media (max-width: 1199.98px) {
  .breadcrumb__content .title {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb__content .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb__content .title {
    font-size: 36px;
  }
}
.breadcrumb__content .breadcrumb {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 10px;
}
.breadcrumb__content .breadcrumb > * {
  font-size: 16px;
  color: var(--tg-theme-primary);
}
.breadcrumb__content .breadcrumb > * a {
  color: var(--tg-common-color-dark);
}
.breadcrumb__content .breadcrumb .breadcrumb-separator {
  line-height: 1;
  font-size: 16px;
  margin-top: 1px;
  font-weight: 700;
  margin-top: 2px;
  opacity: 0.5;
  color: var(--tg-common-color-gray-3);
}
.breadcrumb__shape-wrap img {
  position: absolute;
  z-index: -1;
}
.breadcrumb__shape-wrap img:nth-child(1) {
  left: 100px;
  top: 62px;
}
@media (max-width: 1500px) {
  .breadcrumb__shape-wrap img:nth-child(1) {
    left: 69px;
    top: 52px;
  }
}
.breadcrumb__shape-wrap img:nth-child(2) {
  right: 32%;
  top: 21%;
}
@media (max-width: 1500px) {
  .breadcrumb__shape-wrap img:nth-child(2) {
    right: 38%;
  }
}
@media (max-width: 991.98px) {
  .breadcrumb__shape-wrap img:nth-child(2) {
    right: 31%;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb__shape-wrap img:nth-child(2) {
    display: none;
  }
}
.breadcrumb__shape-wrap img:nth-child(3) {
  right: 20%;
  bottom: 15%;
}
@media (max-width: 1500px) {
  .breadcrumb__shape-wrap img:nth-child(3) {
    right: 23%;
    bottom: 13%;
  }
}
.breadcrumb__shape-wrap img:nth-child(4) {
  right: 8%;
  top: 8%;
}
@media (max-width: 991.98px) {
  .breadcrumb__shape-wrap img:nth-child(4) {
    right: 11%;
    top: 16%;
  }
}
.breadcrumb__shape-wrap img:nth-child(5) {
  right: 0;
  top: 0;
}
@media (max-width: 1199.98px) {
  .breadcrumb__shape-wrap img:nth-child(5) {
    right: -30px;
    width: 400px;
    height: 285px;
  }
}
@media (max-width: 991.98px) {
  .breadcrumb__shape-wrap img:nth-child(5) {
    display: none;
  }
}

/*=============================
    00. Select2 CSS
===============================*/
.tgmenu__search-form .select2-container {
  margin-left: -29px;
}
.tgmenu__search-form .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 37px;
  padding-right: 28px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  font-size: 14px;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
  font-weight: 500;
}
.tgmenu__search-form .select2-container .select2-selection--single {
  height: auto;
}

.course-category-dropdown {
  background: var(--tg-common-color-white);
  border: 1px solid var(--tg-border-2);
  margin: 0 0;
}
.course-category-dropdown .select2-results__options {
  margin-top: 7px;
}
.course-category-dropdown .select2-results__option {
  padding: 1px 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.course-category-dropdown .select2-results__option--selectable:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}

/*=============================
    00. Scroll Top
===============================*/
.scroll__top {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: fixed;
  bottom: -10%;
  right: 50px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 99;
  color: var(--tg-common-color-white);
  text-align: center;
  cursor: pointer;
  background: var(--tg-theme-primary);
  transition: 1s ease;
  border: none;
}
@media (max-width: 1199.98px) {
  .scroll__top {
    right: 25px;
    bottom: 25px;
  }
}
@media (max-width: 991.98px) {
  .scroll__top {
    right: 30px;
  }
}
@media (max-width: 767.98px) {
  .scroll__top {
    right: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
}
.scroll__top.open {
  bottom: 30px;
}
@media (max-width: 767.98px) {
  .scroll__top.open {
    bottom: 15px;
  }
}
.scroll__top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
.scroll__top:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-common-color-white);
}

/*=============================
	00. Section Title
===============================*/
.section__title .sub-title {
  display: inline-block;
  line-height: 1.62;
  background: var(--tg-common-color-gray-2);
  /* background: var(--tg-common-color-lightgreen); */
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  padding: 3px 16px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-common-color-black-3);
  margin: 0 0 14px;
}

.section__title .title {
  font-size: 36px;
  line-height: 1.33;
  margin: 0 0;
  letter-spacing: -0.75px;
  text-transform: capitalize;
  color: var(--tg-theme-primary);
}
@media (max-width: 767.98px) {
  .section__title .title {
    font-size: 29px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section__title .title {
    font-size: 36px;
  }
}
.section__title .title span:not(.svg-icon) {
  color: var(--tg-theme-primary);
  position: relative;
  color: var(--tg-common-color-white);
  padding: 0 13px 0 20px;
  font-weight: var(--tg-fw-bold);
  display: inline-block;
  z-index: 1;
}
.section__title .title span:not(.svg-icon) > svg {
  position: absolute;
  left: 0;
  top: -5px;
  width: 100%;
  height: 59px;
  z-index: -1;
  color: var(--tg-theme-secondary);
}
@media (max-width: 767.98px) {
  .section__title .title span:not(.svg-icon) > svg {
    height: 45px;
    top: -2px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section__title .title span:not(.svg-icon) > svg {
    top: 0;
  }
}
.section__title .title .svg-icon {
  position: absolute;
  right: -50px;
  top: -50px;
  width: 61px;
  height: 68px;
}
.section__title .title .svg-icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
.section__title .title .svg-icon path {
  stroke: var(--tg-heading-color);
}
.section__title p {
  margin-bottom: 0;
  margin-top: 15px;
}
@media (max-width: 767.98px) {
  .section__title p br {
    display: none;
  }
}
.section__title.white-title .sub-title {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.section__title.white-title .title {
  color: var(--tg-common-color-white);
}
.section__title.white-title .title span:not(.svg-icon) {
  color: var(--tg-common-color-white);
}
.section__title.white-title p {
  /* color: #ACAACC; */
  color: #fff;
}
.section__title .desc {
  margin: 10px 0 0;
}

/*=============================
	02. Header
===============================*/
.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}
@media (max-width: 767.98px) {
  .transparent-header {
    top: 0;
  }
}

.tg-header__top {
  background-color: var(--tg-common-color-black-2);
  padding: 11px 0;
}
@media (max-width: 991.98px) {
  .tg-header__top {
    display: none;
  }
}
.tg-header__top-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px 27px;
}
@media (max-width: 991.98px) {
  .tg-header__top-info {
    display: none;
  }
}
.tg-header__top-info li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: #E6EAEF;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  gap: 7px;
}
.tg-header__top-info li img {
  opacity: 0.6;
}
.tg-header__top-info li i {
  color: var(--tg-common-color-white);
  font-size: 20px;
}
.tg-header__top-info li a {
  color: #E6EAEF;
}
.tg-header__top-info li a:hover {
  color: var(--tg-common-color-white);
}
@media (max-width: 991.98px) {
  .tg-header__top-info li::after {
    display: none;
  }
  .tg-header__top-info li:last-child {
    display: none;
  }
}
.tg-header__top-right {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px 27px;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .tg-header__top-right {
    justify-content: center;
  }
}
.tg-header__top-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px 12px;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .tg-header__top-social {
    justify-content: center;
  }
}
.tg-header__top-social li {
  color: var(--tg-common-color-white);
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
}
.tg-header__top-social li:first-child {
  opacity: 0.5;
}
.tg-header__top-social li a {
  color: #E7EFFC;
}
.tg-header__top-social li a:hover {
  color: var(--tg-common-color-white);
}
.tg-header__phone {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #E6EAEF;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
}
.tg-header__phone a {
  color: #E6EAEF;
}
.tg-header__phone a:hover {
  color: var(--tg-common-color-white);
}
.tg-header__phone img {
  opacity: 0.6;
}
.tg-header__area {
  padding: 5px 0;
}
@media (max-width: 1500px) {
  .tg-header__area {
    padding: 10px 0;
  }
}
@media (max-width: 1199.98px) {
  .tg-header__area {
    padding: 20px 0;
  }
}
.tg-header__area .mobile-nav-toggler {
  position: relative;
  font-size: 30px;
  cursor: pointer;
  line-height: 1;
  color: var(--tg-theme-primary);
  display: none;
}
@media (max-width: 1199.98px) {
  .tg-header__area .mobile-nav-toggler {
    display: block;
  }
}
.tg-header__area.sticky-menu {
  padding: 0 0;
}
@media (max-width: 1199.98px) {
  .tg-header__area.sticky-menu {
    padding: 20px 0;
  }
}
.tg-header__style-two .tgmenu__search-form {
  width: 450px;
}
@media (max-width: 1500px) {
  .tg-header__style-two .tgmenu__search-form {
    width: 360px;
  }
}
@media (max-width: 1199.98px) {
  .tg-header__style-two .tgmenu__search-form {
    width: 400px;
  }
}
@media (max-width: 991.98px) {
  .tg-header__style-two .tgmenu__search-form {
    width: auto;
  }
}

.tgmenu__nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 991.98px) {
  .tgmenu__nav {
    justify-content: space-between;
  }
}
.tgmenu__navbar-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
.tgmenu__navbar-wrap ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin: 0 auto 0 73px; */
}
@media (max-width: 1500px) {
  .tgmenu__navbar-wrap ul {
    margin: 0 auto 0 40px;
  }
}
.tgmenu__navbar-wrap ul li {
  display: block;
  position: relative;
  list-style: none;
}
.tgmenu__navbar-wrap ul li a {
  font-size: 16px;
  font-weight: var(--tg-fw-medium);
  text-transform: capitalize;
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  padding: 37px 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  line-height: 1;
  position: relative;
  z-index: 1;
}
.tgmenu__navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid #f5f5f5;
  background: var(--tg-common-color-white);
  margin: 0;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}
.tgmenu__navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.tgmenu__navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.tgmenu__navbar-wrap ul li .sub-menu li a {
  padding: 8px 15px 8px 25px;
  line-height: 1.4;
  display: block;
  color: var(--tg-heading-color);
  text-transform: capitalize;
}
.tgmenu__navbar-wrap ul li .sub-menu li:hover > a, .tgmenu__navbar-wrap ul li .sub-menu li.active > a {
  color: var(--tg-theme-primary);
}
.tgmenu__navbar-wrap ul li .sub-menu li:hover > a {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  -ms-transform: translateX(8px);
  -o-transform: translateX(8px);
  transform: translateX(8px);
}
.tgmenu__navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.tgmenu__navbar-wrap > ul > li.active a, .tgmenu__navbar-wrap > ul > li:hover a {
  color: var(--tg-theme-primary);
}
.tgmenu__main-menu li.menu-item-has-children .dropdown-btn {
  display: none;
}
.tgmenu__main-menu li.menu-item-has-children > a::after {
  content: "\f105";
  display: block;
  font-family: "flaticon_skill_grow";
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 14px;
  font-weight: var(--tg-fw-bold);
  margin-left: 6px;
}
.tgmenu__search {
  margin-inline-end: 25px;
}
@media (max-width: 1199.98px) {
  .tgmenu__search {
    margin-left: auto;
  }
}
.tgmenu__search-form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 400px;
  /* width: 535px; */
  border-radius: 100px;
  border: 1px solid #D3D2DF;
}
@media (max-width: 1800px) {
  .tgmenu__search-form {
    width: 485px;
  }
}
@media (max-width: 1500px) {
  .tgmenu__search-form {
    width: 380px;
  }
}
@media (max-width: 1199.98px) {
  .tgmenu__search-form {
    width: 500px;
  }
}
@media (max-width: 991.98px) {
  .tgmenu__search-form {
    width: auto;
  }
}
.tgmenu__search-form .select-grp {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 12px 0 12px 17px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  width: 153px;
  flex: 0 0 auto;
  position: relative;
}
.tgmenu__search-form .select-grp::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 20px;
  background: #BDBABB;
}
@media (max-width: 991.98px) {
  .tgmenu__search-form .select-grp::after {
    display: none;
  }
}
.tgmenu__search-form .select-grp svg {
  width: 16px;
  height: 16px;
  flex: 0 0 auto;
}
.tgmenu__search-form .select-grp path {
  fill: var(--tg-theme-primary);
}
.tgmenu__search-form .form-select {
  outline: none;
  box-shadow: none;
  border: none;
  padding-left: 8px;
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  font-size: 14px;
}
.tgmenu__search-form .input-grp {
  position: relative;
  flex-grow: 1;
  margin-left: -1px;
}
@media (max-width: 991.98px) {
  .tgmenu__search-form .input-grp {
    display: none;
  }
}
.tgmenu__search-form input {
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding: 12px 50px 10px 20px;
  height: 50px;
  font-size: 15px;
  font-family: var(--tg-heading-font-family);
}
.tgmenu__search-form input::placeholder {
  color: #8D9DB5;
  font-size: 14px;
}
.tgmenu__search-form [type=submit] {
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 4px;
  width: 44px;
  height: 44px;
  border: none;
  padding: 0 0;
  /* background: var(--tg-theme-primary); */
  background: var(--tg-theme-secondary);
  font-size: 20px;
  color: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.tgmenu__search-form [type=submit]:hover {
  /* background: var(--tg-theme-secondary); */
  background: var(--tg-theme-primary);
  /* color: var(--tg-heading-color); */
}
.tgmenu__search-bar {
  flex-grow: 1;
}
@media (max-width: 1500px) {
  .tgmenu__search-bar {
    max-width: 240px;
  }
}
@media (max-width: 1199.98px) {
  .tgmenu__search-bar {
    max-width: 100%;
  }
}
@media (max-width: 991.98px) {
  .tgmenu__search-bar {
    display: none;
  }
}
.tgmenu__search-bar form {
  position: relative;
}
.tgmenu__search-bar form input {
  display: block;
  width: 100%;
  background: var(--tg-common-color-white);
  border: 1px solid var(--tg-border-2);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  padding: 12px 50px 10px 20px;
  height: 45px;
  font-size: 15px;
  font-family: var(--tg-heading-font-family);
}
.tgmenu__search-bar form input::placeholder {
  color: #8D9DB5;
  font-size: 14px;
}
.tgmenu__search-bar form [type=submit] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
  right: 10px;
  border: none;
  padding: 0 0;
  background: transparent;
  font-size: 24px;
  color: #8D9DB5;
}
.tgmenu__search-bar form [type=submit]:hover {
  color: var(--tg-theme-primary);
}
@media (max-width: 1199.98px) {
  .tgmenu__action {
    margin: 0 25px 0 0;
  }
}
@media (max-width: 767.98px) {
  .tgmenu__action {
    display: none;
  }
}
.tgmenu__action > ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
}
.tgmenu__action > ul li {
  position: relative;
  padding: 0 6px 0 0;
  flex: 0 0 auto;
}
.tgmenu__action > ul li .cart-count {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #7F7E97;
  color: #7F7E97;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.tgmenu__action > ul li .cart-count span {
  position: absolute;
  top: -9px;
  right: 0;
  width: 22px;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-secondary);
  color: var(--tg-heading-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  z-index: 1;
}
.tgmenu__action > ul li .cart-count:hover {
  background: var(--tg-theme-primary);
  border-color: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.tgmenu__action > ul li:first-child {
  margin-left: 0;
}
@media (max-width: 1500px) {
  .tgmenu__action > ul li.free-btn {
    display: none;
  }
}
.tgmenu__action > ul li.login-btn {
  padding: 0 0;
  margin-inline-start: 15px;
}
@media (max-width: 1199.98px) {
  .tgmenu__action > ul li.login-btn {
    display: none;
  }
}
.tgmenu__action > ul li.login-btn a {
  display: block;
  border-radius: 50px;
  padding: 12px 26px;
  border: 1px solid rgba(6, 35, 91, 0.19);
  background: var(--tg-theme-secondary);
  font-size: 15px;
  color: var(--tg-heading-color);
  font-weight: 600;
  line-height: 18px;
}
.tgmenu__action > ul li.login-btn a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.tgmenu__action-two .mini-cart-icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
.tgmenu__action-two .mini-cart-icon .cart-count-two {
  position: relative;
  font-size: 30px;
  line-height: 0;
  color: var(--tg-common-color-black-2);
}
.tgmenu__action-two .mini-cart-icon .cart-count-two .mini-cart-count {
  position: absolute;
  width: 23px;
  height: 23px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  top: -10px;
  right: -13px;
}
.tgmenu__action-two .mini-cart-icon .cart-count-two:hover {
  color: var(--tg-theme-secondary);
}
.tgmenu__action-two .mini-cart-icon strong {
  font-size: 14px;
  font-weight: 500;
  color: var(--tg-common-color-black-2);
  line-height: 1.2;
}
.tgmenu__categories {
  margin-right: 12px;
}
@media (max-width: 1199.98px) {
  .tgmenu__categories {
    margin-left: auto;
  }
}
.tgmenu__categories .dropdown-toggle {
  font-family: var(--tg-heading-font-family);
  border: 1px solid var(--tg-border-2);
  background-color: var(--tg-common-color-white);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  color: var(--tg-body-color);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 9px 17px;
  gap: 11px;
}
.tgmenu__categories .dropdown-toggle svg {
  color: var(--tg-theme-primary);
}
.tgmenu__categories .dropdown-toggle::after {
  content: "";
  display: block;
  margin: 0 0;
  border: none;
  background-image: url(../img/icons/down_arrow.html);
  width: 12px;
  height: 6px;
}
.tgmenu__categories .dropdown-menu {
  width: 100%;
  min-width: 100%;
  background-color: var(--tg-common-color-white);
  border: 1px solid var(--tg-border-2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.tgmenu__categories .dropdown-item {
  color: var(--tg-body-color);
}
.tgmenu__categories .dropdown-item:hover, .tgmenu__categories .dropdown-item:focus {
  background-color: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}

.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: var(--tg-common-color-white);
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
}
.sticky-menu ul li .sub-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  -o-border-radius: 0 0 6px 6px;
  -ms-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}

#header-fixed-height.active-height {
  display: block;
  height: 90px;
}

/*=============================
	03. Mobile Menu
===============================*/
.tgmobile__search {
  padding: 0 20px 25px 25px;
}
.tgmobile__search form {
  position: relative;
}
.tgmobile__search input {
  display: block;
  width: 100%;
  border: none;
  padding: 10px 45px 10px 20px;
  font-size: 15px;
  height: 45px;
  background: var(--tg-common-color-gray);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.tgmobile__search input::placeholder {
  font-size: 15px;
  color: var(--tg-body-color);
}
.tgmobile__search button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  padding: 0;
  right: 20px;
  line-height: 1;
  background: transparent;
  color: var(--tg-heading-color);
}
.tgmobile__menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) e;
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: translateX(101%);
  -moz-transform: translateX(101%);
  -ms-transform: translateX(101%);
  -o-transform: translateX(101%);
  transform: translateX(101%);
}
.tgmobile__menu .navbar-collapse {
  display: block !important;
}
.tgmobile__menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.tgmobile__menu .nav-logo img {
  width: 150px;
}
.tgmobile__menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}
.tgmobile__menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tgmobile__menu .navigation li.active > a {
  color: var(--tg-theme-primary);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 20px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--tg-heading-color);
  background: var(--tg-common-color-gray);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn .plus-line {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 12px;
  height: 2px;
  background-color: var(--tg-common-color-black);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn .plus-line::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 2px;
  height: 12px;
  background-color: var(--tg-common-color-black);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open {
  background-color: var(--tg-theme-primary);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line {
  background-color: var(--tg-common-color-white);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line::after {
  display: none;
}
.tgmobile__menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 1.5;
  padding: 10px 60px 10px 25px;
  font-size: 16px;
  font-weight: 500;
  color: var(--tg-heading-color);
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
}
.tgmobile__menu .navigation li > a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 2px;
  background: var(--tg-theme-primary);
  pointer-events: none;
}
.tgmobile__menu .navigation li ul li > a {
  margin-left: 20px;
}
.tgmobile__menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.tgmobile__menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.tgmobile__menu .navigation li > ul {
  display: none;
}
.tgmobile__menu .navigation li > ul > li > ul {
  display: none;
}
.tgmobile__menu .navigation ul {
  padding: 0;
  margin: 0;
}
.tgmobile__menu .navigation ul li a {
  display: block;
}
.tgmobile__menu .navigation ul li ul li > a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}
.tgmobile__menu .navigation:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tgmobile__menu .navigation > li > ul > li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tgmobile__menu .navigation > li.active > a::before {
  height: 100%;
}
.tgmobile__menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 14px;
  color: var(--tg-theme-primary);
  cursor: pointer;
  padding: 8px;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.tgmobile__menu .close-btn i[class^=flaticon-]:before {
  font-weight: var(--tg-fw-bold) !important;
}
.tgmobile__menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
}
.tgmobile__menu .social-links ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.tgmobile__menu .social-links ul li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 10px;
}
.tgmobile__menu .social-links ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: var(--tg-body-color);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid #efefef;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.tgmobile__menu .social-links ul li a:hover {
  border-color: var(--tg-theme-primary);
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.tgmobile__menu .tgmenu__action {
  padding: 0 0;
  margin: 0 0;
}
.tgmobile__menu .tgmenu__action > ul {
  margin: 0 0;
  padding: 30px 20px 0;
  justify-content: center;
  gap: 0 15px;
}
.tgmobile__menu .tgmenu__action > ul li {
  margin: 0 0;
}
.tgmobile__menu .tgmenu__action > ul .header-btn {
  display: block;
}
.tgmobile__menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--tg-common-color-white);
  padding: 0px 0px;
  z-index: 5;
  box-shadow: -9px 0 14px 0px rgba(0, 0, 0, 0.06);
}
.tgmobile__menu-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: var(--tg-common-color-white);
  margin-right: 30px;
  top: 15px;
}

.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .tgmobile__menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu-visible .tgmobile__menu-backdrop {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-visible .tgmobile__menu .close-btn {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

/*=============================
    04. Banner
===============================*/
.banner-bg {
  min-height: 520px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  padding: 60px 0 0;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .banner-bg {
    min-height: auto;
  }
}
.banner-bg .line-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 767.98px) {
  .banner-bg .line-shape {
    display: none;
  }
}
.banner-bg-two {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  padding: 80px 0 60px;
  background-size: cover;
  background-position: bottom;
  z-index: 1;
}
@media (max-width: 1500px) {
  .banner-bg-two {
    padding: 60px 0 60px;
  }
}
@media (max-width: 991.98px) {
  .banner-bg-two {
    padding: 60px 0 25px;
  }
}
.banner-bg-two .line-shape-two {
  position: absolute;
  left: 0;
  top: 20%;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 767.98px) {
  .banner-bg-two .line-shape-two {
    display: none;
  }
}
.banner__content {
  margin: 60px 0 100px;
  position: relative;
  z-index: 3;
}
@media (max-width: 1500px) {
  .banner__content {
    margin: 60px 0 70px;
  }
}
@media (max-width: 1199.98px) {
  .banner__content {
    margin: 40px 0 60px;
  }
}
@media (max-width: 991.98px) {
  .banner__content {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .banner__content {
    margin: 0 0 60px;
  }
}
.banner__content .sub-title {
  display: inline-block;
  line-height: 1;
  background: var(--tg-common-color-gray);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 7px 15px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-theme-primary);
  margin: 0 0 16px;
}
.banner__content .title {
  font-size: 40px;
  line-height: 1.5;
  font-weight: var(--tg-fw-medium);
  margin: 0 0 12px;
}
@media (max-width: 1199.98px) {
  .banner__content .title {
    font-size: 35px;
  }
}
@media (max-width: 767.98px) {
  .banner__content .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__content .title {
    font-size: 40px;
  }
}

.banner__content .title span:not(.svg-icon) {
  color: var(--tg-theme-primary);
  position: relative;
  color: var(--tg-common-color-white);
  padding: 8px 13px 7px 20px;
  font-weight: var(--tg-fw-bold);
  z-index: 1;
  /* font-size: 29px; */
}
.banner__content .title span:not(.svg-icon) > svg {
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  height: 100%;
  z-index: -1;
  color: var(--tg-theme-secondary);
}
.banner__content .title .svg-icon {
  position: absolute;
  right: -50px;
  top: -50px;
  width: 61px;
  height: 68px;
}
@media (max-width: 767.98px) {
  .banner__content .title .svg-icon {
    display: none;
  }
}
.banner__content .title .svg-icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
.banner__content .title .svg-icon path {
  stroke: var(--tg-heading-color);
}
.banner__content p {
  max-width: 90%;
  margin: 0 0 0;
  font-size: 18px;
  line-height: 1.6;
}
@media (max-width: 1199.98px) {
  .banner__content p {
    max-width: 100%;
  }
}
.banner__content-two {
  margin: 120px 0 60px;
}
@media (max-width: 1500px) {
  .banner__content-two {
    margin: 80px 0 60px;
  }
}
@media (max-width: 991.98px) {
  .banner__content-two {
    margin: 0 0 60px;
    text-align: center;
  }
}
.banner__content-two .title {
  margin-bottom: 25px;
  font-size: 44px;
  font-weight: 700;
}
@media (max-width: 1199.98px) {
  .banner__content-two .title {
    font-size: 38px;
  }
}
@media (max-width: 767.98px) {
  .banner__content-two .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__content-two .title {
    font-size: 40px;
  }
}
.banner__content-two .title span {
  position: relative;
  color: var(--tg-common-color-white);
  padding: 0 13px 0 20px;
  font-weight: var(--tg-fw-bold);
  z-index: 1;
}
@media (max-width: 767.98px) {
  .banner__content-two .title span {
    padding: 0 10px 0 10px;
  }
}
.banner__content-two .title span svg {
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  height: 100%;
  z-index: -1;
  color: var(--tg-theme-secondary);
}
.banner__btn-wrap {
  margin: 34px 0 0;
}
@media (max-width: 991.98px) {
  .banner__btn-wrap {
    margin: 28px 0 0;
  }
  .banner__btn-wrap .tg-button-wrap {
    justify-content: center;
  }
}
.banner__btn-two {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
}
@media (max-width: 991.98px) {
  .banner__btn-two {
    justify-content: center;
  }
}
.banner__btn-two .play-btn {
  font-size: 15px;
  font-weight: 500;
  font-family: var(--tg-heading-font-family);
  line-height: 1.3;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--tg-heading-color);
}
.banner__btn-two .play-btn i {
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-theme-secondary);
  border: 1px solid var(--tg-heading-color);
  color: var(--tg-heading-color);
  font-size: 16px;
  -webkit-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -moz-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -ms-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -o-box-shadow: 4px 4px 0px 0px #3D3D3D;
  box-shadow: 4px 4px 0px 0px #3D3D3D;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.banner__btn-two .play-btn:hover {
  color: var(--tg-theme-secondary);
}
.banner__btn-two .play-btn:hover i {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.banner__images {
  position: relative;
  padding-right: 118px;
  text-align: right;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .banner__images {
    padding-right: 105px;
  }
}
@media (max-width: 991.98px) {
  .banner__images {
    margin: 0 auto;
    text-align: center;
    max-width: 80%;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .banner__images {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__images {
    max-width: 90%;
  }
}
.banner__images .main-img {
  max-width: inherit;
}
@media (max-width: 1500px) {
  .banner__images .main-img {
    max-width: 100%;
  }
}
.banner__images .shape {
  position: absolute;
  z-index: -1;
}
.banner__images .shape.big-shape {
  max-width: 618px;
  bottom: -85px;
  right: -22px;
}
.banner__images .shape.bg-dots {
  max-width: 495px;
  right: 30px;
  bottom: -33px;
  z-index: -2;
}
@media (max-width: 767.98px) {
  .banner__images .shape.bg-dots {
    max-width: 100%;
    right: 0;
    bottom: 18%;
  }
}
.banner__images .shape.small-shape {
  max-width: 136px;
  left: 35px;
  bottom: -24px;
  z-index: -2;
}
@media (max-width: 1199.98px) {
  .banner__images .shape.small-shape {
    left: 10px;
  }
}
.banner__images-two {
  position: relative;
  text-align: center;
  margin-right: 60px;
}
@media (max-width: 1199.98px) {
  .banner__images-two {
    margin-right: 0;
  }
}
@media (max-width: 1500px) {
  .banner__images-two img {
    width: 400px;
  }
}
@media (max-width: 1199.98px) {
  .banner__images-two img {
    width: 350px;
  }
}
.banner__images-two .big-shape {
  position: absolute;
  z-index: -1;
  left: 8%;
  top: 2%;
  color: #FFC738;
}
@media (max-width: 1500px) {
  .banner__images-two .big-shape {
    top: 5%;
  }
}
@media (max-width: 1199.98px) {
  .banner__images-two .big-shape {
    left: 0;
    width: 400px;
    top: 2%;
  }
  .banner__images-two .big-shape svg {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .banner__images-two .big-shape {
    width: 100%;
    max-width: 400px;
  }
}
.banner__images-two .svg-icon {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 35%;
  top: -5%;
}
@media (max-width: 991.98px) {
  .banner__images-two .svg-icon {
    left: 30%;
    top: -8%;
  }
}
.banner__images-two .svg-icon svg {
  width: 100%;
  height: 100%;
  color: #031333;
  display: block;
}
.banner__images-two .about__enrolled {
  left: 0;
  top: 41%;
  bottom: auto;
}
@media (max-width: 1199.98px) {
  .banner__images-two .about__enrolled {
    display: none;
  }
}
.banner__student {
  display: inline-flex;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid var(--tg-common-color-gray-5);
  background: var(--tg-common-color-white);
  -webkit-box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 18%;
  top: 16%;
  padding: 15px 18px;
  gap: 10px;
}
@media (max-width: 1199.98px) {
  .banner__student {
    right: 0;
    top: 13%;
  }
}
.banner__student .icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-common-color-green);
  color: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 4px 4px 0px rgba(33, 150, 83, 0.5);
  -moz-box-shadow: 4px 4px 0px rgba(33, 150, 83, 0.5);
  -ms-box-shadow: 4px 4px 0px rgba(33, 150, 83, 0.5);
  -o-box-shadow: 4px 4px 0px rgba(33, 150, 83, 0.5);
  box-shadow: 4px 4px 0px rgba(33, 150, 83, 0.5);
  border: 1px solid #149959;
}
.banner__student .content {
  text-align: left;
}
.banner__student .content span {
  display: block;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.banner__student .content .title {
  margin-bottom: 0;
  font-size: 24px;
  font-family: var(--tg-body-font-family);
  font-weight: 800;
  line-height: 1;
}
.banner__author {
  position: absolute;
  top: 30px;
  left: 23px;
  width: 175px;
  padding: 11px 30px 11px 14px;
  border-radius: 6px;
  background: var(--tg-common-color-white);
  box-shadow: 9px 10px 0px 0px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
@media (max-width: 1199.98px) {
  .banner__author {
    top: -30px;
    left: -10px;
    width: 155px;
    padding: 10px 10px 10px 10px;
  }
}
@media (max-width: 991.98px) {
  .banner__author {
    top: 0px;
    left: 10px;
  }
}
@media (max-width: 767.98px) {
  .banner__author {
    display: none;
  }
}
.banner__author-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
}
.banner__author-item .image {
  flex: 0 0 auto;
}
.banner__author-item .image img {
  max-width: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.banner__author-item .name {
  flex-grow: 1;
  margin-bottom: 0;
  font-size: 14px;
}
.banner__author .arrow-shape {
  position: absolute;
  left: calc(100% - 11px);
  top: 9px;
  z-index: -1;
}

/*=============================
    05. Slider
===============================*/
.slider__active [data-animation-in] {
  opacity: 0;
}
.slider__item {
  align-items: center;
  background-position: center;
  background-size: cover;
  padding: 135px 0 140px;
  min-height: 600px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1500px) {
  .slider__item {
    padding: 110px 0 120px;
    min-height: 560px;
  }
}
.slider__item .top-shape, .slider__item .bottom-shape {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.slider__item .top-shape {
  left: 4%;
  top: 11%;
}
@media (max-width: 1500px) {
  .slider__item .top-shape {
    max-width: 80px;
  }
}
.slider__item .bottom-shape {
  left: 0;
  bottom: 0;
}
@media (max-width: 1500px) {
  .slider__item .bottom-shape {
    max-width: 350px;
  }
}
.slider__content {
  overflow: hidden;
}
.slider__content .sub-title {
  display: inline-block;
  line-height: 1.3;
  background: var(--tg-common-color-gray);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 5px 15px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-theme-primary);
  margin: 0 0 13px;
}
@media (max-width: 1500px) {
  .slider__content .sub-title {
    padding: 0 0;
    background: transparent;
    font-weight: var(--tg-fw-semi-bold);
  }
}
.slider__content .title {
  font-size: 48px;
  margin: 0 0 30px;
  letter-spacing: -1px;
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .slider__content .title {
    font-size: 38px;
    margin: 0 0 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider__content .title {
    font-size: 40px;
    margin: 0 0 30px;
  }
}
.slider__content .title span:not(.svg-icon) {
  color: var(--tg-theme-primary);
  position: relative;
  display: inline-block;
  z-index: 1;
}
.slider__content .title .svg-icon {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  margin: 0 auto;
  bottom: -14px;
  height: 25px;
  width: 145px;
  pointer-events: none;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .slider__content .title .svg-icon {
    bottom: -15px;
  }
}
.slider__content .title .svg-icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
.slider__content .title .svg-icon path {
  stroke: var(--tg-common-color-yellow);
}
.slider__content .list-wrap {
  margin: 0 0 30px;
}
.slider__content .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-family: var(--tg-heading-font-family);
  color: #39557E;
  gap: 7px;
  margin: 0 0 3px;
}
.slider__content .list-wrap li:last-child {
  margin: 0 0;
}
.slider__content .list-wrap li i {
  color: var(--tg-common-color-yellow);
  margin: 6px 0 0;
}

/*=============================
    06. Brand
===============================*/
.brand-area {
  background: var(--tg-common-color-black);
  padding: 20px 0;
}
.brand-area .container-fluid {
  padding: 0 0;
}
.brand-area-two {
  transform: rotate(-3.5deg);
  position: relative;
  z-index: 2;
  margin-top: -60px;
}
@media (max-width: 767.98px) {
  .brand-area-two {
    transform: rotate(0);
  }
}
.brand__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 0 35px;
}
.brand__item a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: center;
}
.brand__item a:hover img {
  opacity: 0.7;
}

.marquee_mode {
  overflow: hidden;
  position: relative;
}
.marquee_mode .js-marquee {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 0 35px;
}

/*=============================
    07. About
===============================*/
.about__images {
  margin-left: 196px;
  margin-right: -25px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about__images {
    margin-left: 50px;
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  .about__images {
    margin-left: 0;
    margin-bottom: 50px;
  }
}
.about__images .shape {
  position: absolute;
  top: 36px;
  right: 132px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .about__images .shape {
    right: 95px;
  }
}
@media (max-width: 991.98px) {
  .about__images .shape {
    right: 135px;
  }
}
@media (max-width: 767.98px) {
  .about__images .shape {
    right: 80px;
  }
}
.about__images .popup-video {
  position: absolute;
  width: 76px;
  height: 76px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--tg-common-color-white);
  color: var(--tg-common-color-red);
  z-index: 1;
}
.about__images .popup-video:hover {
  color: var(--tg-common-color-white);
  background: var(--tg-common-color-red);
}
.about__images-three {
  position: relative;
}
@media (max-width: 991.98px) {
  .about__images-three {
    margin-bottom: 50px;
  }
}
.about__images-three img {
  -webkit-border-radius: 400px;
  -moz-border-radius: 400px;
  -o-border-radius: 400px;
  -ms-border-radius: 400px;
  border-radius: 400px;
}
.about__images-three .svg-icon {
  width: 55px;
  height: 55px;
  position: absolute;
  right: 5%;
  top: 2%;
}
@media (max-width: 1199.98px) {
  .about__images-three .svg-icon {
    right: 0;
    top: 0;
  }
}
.about__images-three .svg-icon svg {
  width: 100%;
  height: 100%;
  color: var(--tg-theme-primary);
}
.about__images-three .popup-video {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.about__images-three .popup-video svg {
  width: 16px;
  color: var(--tg-theme-primary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.about__images-three .popup-video:hover {
  background: var(--tg-theme-primary);
}
.about__images-three .popup-video:hover svg {
  color: var(--tg-common-color-white);
}
.about__enrolled {
  position: absolute;
  left: -90px;
  bottom: 35px;
  background: var(--tg-common-color-white);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #B2BBCC;
  box-shadow: -8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 12px 20px;
}
@media (max-width: 1199.98px) {
  .about__enrolled {
    left: -50px;
    bottom: 30px;
  }
}
@media (max-width: 991.98px) {
  .about__enrolled {
    display: none;
  }
}
.about__enrolled .title {
  font-size: 16px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-theme-primary);
  margin: 0 0 2px;
}
.about__enrolled .title span {
  font-weight: var(--tg-fw-bold);
  color: var(--tg-heading-color);
}
.about__enrolled img {
  max-width: 155px;
}
.about__content {
  margin-left: 65px;
  margin-right: 110px;
}
@media (max-width: 1199.98px) {
  .about__content {
    margin: 0 0;
  }
}
.about__content .section__title {
  margin: 0 0 12px;
}
.about__content .desc {
  margin: 0 0 22px;
}
.about__content .tg-button-wrap {
  margin-top: 40px;
}
.about__content-three {
  margin-left: 40px;
  width: 90%;
}
@media (max-width: 1199.98px) {
  .about__content-three {
    margin-left: 0;
    width: 100%;
  }
}
.about__content-three .desc {
  margin-bottom: 22px;
}
.about__content-three .about__info-list {
  margin-bottom: 30px;
}
.about__info-list-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 13px;
  margin: 0 0 15px;
}
.about__info-list-item:last-child {
  margin: 0 0;
}
.about__info-list-item i {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30px;
  background: var(--tg-theme-secondary);
  border: 1px solid #282568;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 4px 3px 0px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: var(--tg-heading-color);
}
.about__info-list-item p {
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
  font-size: 18px;
  font-weight: var(--tg-fw-semi-bold);
  margin: 0 0;
}
.about__info-list-item:hover i {
  box-shadow: none;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/*=============================
    08. Courses
===============================*/
.section-pt-120 {
  padding-top: 120px;
}
@media (max-width: 767.98px) {
  .section-pt-120 {
    padding-top: 100px;
  }
}

.section-pb-90 {
  padding-bottom: 90px;
}
@media (max-width: 767.98px) {
  .section-pb-90 {
    padding-bottom: 70px;
  }
}

.section-pb-120 {
  padding-bottom: 120px;
}
@media (max-width: 767.98px) {
  .section-pb-120 {
    padding-bottom: 100px;
  }
}

.courses-area {
  position: relative;
  background-position: center;
  background-size: cover;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .courses-area {
    padding: 100px 0;
  }
}
.courses-area .section__title-wrap {
  margin: 0 0 50px;
}
@media (max-width: 767.98px) {
  .courses-area .section__title-wrap {
    margin: 0 0 40px;
  }
}
@media (max-width: 1199.98px) {
  .courses-area .section__title-wrap {
    margin: 0 0 30px;
  }
}
.courses__nav .nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 0 18px;
  border: none;
  gap: 5px 35px;
  position: relative;
}
.courses__nav .nav::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #D9D9F3;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .courses__nav .nav {
    gap: 5px 20px;
    padding: 0 0 7px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .courses__nav .nav {
    padding: 0 0 15px;
  }
}
.courses__nav .nav .nav-item .nav-link {
  font-weight: var(--tg-fw-medium);
  color: #7F7E97;
  padding: 0 0;
  border: none;
  position: relative;
  background: transparent;
}
.courses__nav .nav .nav-item .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -18px;
  right: 0;
  width: 0;
  height: 6px;
  background: var(--tg-theme-primary);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767.98px) {
  .courses__nav .nav .nav-item .nav-link::after {
    bottom: -7px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .courses__nav .nav .nav-item .nav-link::after {
    bottom: -15px;
  }
}
.courses__nav .nav .nav-item .nav-link.active {
  color: var(--tg-heading-color);
}
.courses__nav .nav .nav-item .nav-link.active::after {
  width: 60px;
}
.courses__item {
  margin: 0 0 30px;
  background: var(--tg-common-color-white);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 25px 25px 32px;
  border: 1px solid #B5B5C3;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .courses__item {
    padding: 25px 20px 32px;
  }
}
.courses__item:hover {
  /* filter: drop-shadow(10px 10px 0px #CAC9D6); */
}
.courses__item-two {
  padding: 0;
}
.courses__item-three {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 30px;
  padding: 25px 30px 25px 25px;
}
@media (max-width: 1199.98px) {
  .courses__item-three {
    gap: 20px;
    padding: 20px 20px 20px 20px;
  }
}
@media (max-width: 767.98px) {
  .courses__item-three {
    flex-direction: column;
  }
}
.courses__item-three .courses__item-thumb {
  margin: 0;
  flex: 0 0 auto;
}
.courses__item-three .courses__item-thumb a {
  height: 100%;
}
.courses__item-three .courses__item-thumb img {
  width: 280px;
  object-fit: cover;
  height: 100%;
  min-height: 210px;
}
@media (max-width: 767.98px) {
  .courses__item-three .courses__item-thumb img {
    width: 100%;
  }
}
.courses__item-three .courses__item-content {
  flex-grow: 1;
}
.courses__item-three .courses__item-content .info {
  margin-bottom: 0;
  margin-top: 8px;
}
.courses__item-three .courses__item-tag {
  gap: 15px;
}
.courses__item-three .courses__item-meta {
  margin: 0 0 8px;
}
.courses__item-three .courses__item-meta .price {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  color: var(--tg-theme-primary);
  gap: 8px;
}
.courses__item-three .courses__item-meta .price del {
  font-size: 14px;
  font-weight: 500;
  color: #B2BBCC;
}
.courses__item-thumb {
  position: relative;
  margin: 0 0 20px;
}
.courses__item-thumb-two {
  padding: 25px 25px 0 25px;
}
@media (max-width: 1500px) {
  .courses__item-thumb-two {
    padding: 20px 20px 0 20px;
  }
}
.courses__item-thumb img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 6px;
}
@media (max-width: 767.98px) {
  .courses__item-thumb img {
    height: auto;
  }
}
.courses__item-content .title {
  font-size: 18px;
  font-weight: var(--tg-fw-semi-bold);
  line-height: 1.4;
  margin: 0 0 15px;
}
.courses__item-content .title a {
  display: inline;
  background-image: linear-gradient(var(--tg-heading-color), var(--tg-heading-color)), linear-gradient(var(--tg-heading-color), var(--tg-heading-color));
  background-size: 0% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.courses__item-content .title a:hover {
  color: inherit;
  background-size: 0 2px, 100% 2px;
}
.courses__item-content-two {
  padding: 0 25px 20px;
}
@media (max-width: 1500px) {
  .courses__item-content-two {
    padding: 0 20px 20px;
  }
}
.courses__item-content-two .price {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  color: var(--tg-theme-primary);
}
.courses__item-content-two .price del {
  font-size: 14px;
  font-weight: 400;
  color: var(--tg-common-color-gray-3);
}
.courses__item-content-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.courses__item-content-bottom > .author-two a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  color: var(--tg-body-color);
}
.courses__item-content-bottom > .author-two a img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.courses__item-content-bottom > .author-two a:hover {
  color: var(--tg-theme-secondary);
}
.courses__item-meta {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px 20px;
  margin: 0 0 15px;
  flex-wrap: wrap;
}
.courses__item-meta li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.courses__item-meta li i {
  margin-right: 7px;
}
.courses__item-tag a {
  font-size: 13px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-heading-color);
  background: #EFEFF2;
  display: block;
  line-height: 1;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  padding: 7px 13px;
}
.courses__item-tag a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.courses__item .avg-rating {
  color: #7F7E97;
  font-size: 14px;
  line-height: 1;
}
.courses__item .avg-rating i {
  color: var(--tg-common-color-yellow);
  letter-spacing: 0;
  margin-right: 5px;
}
.courses__item .author {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 0 0;
  line-height: 1;
  gap: 5px;
}
.courses__item .author a {
  flex: 0 0 auto;
  color: var(--tg-heading-color);
}
.courses__item .author a:hover {
  color: var(--tg-theme-primary);
}
.courses__item-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 23px 0 0;
}
.courses__item-bottom .button a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 10px 19px;
  align-items: center;
  gap: 6px;
  border-radius: 50px;
  border: 1px solid #000;
  background: var(--tg-theme-secondary);
  box-shadow: 4px 4px 0px 0px #3D3D3D;
  font-family: var(--tg-heading-font-family);
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-heading-color);
}
.courses__item-bottom .button a:hover {
  box-shadow: none;
}
.courses__item-bottom .price {
  font-size: 20px;
  line-height: 1;
  color: var(--tg-theme-primary);
  font-weight: var(--tg-fw-bold);
  margin: 0 0;
}
@media (max-width: 991.98px) {
  .courses__item-bottom .price {
    font-size: 18px;
  }
}
.courses__item-bottom .price del {
  font-size: 16px;
  color: #8D9DB5;
  margin-right: 4px;
}
.courses__item-bottom-two {
  border-top: 1px solid #B5B5C3;
  padding: 13px 25px;
}
@media (max-width: 1500px) {
  .courses__item-bottom-two {
    padding: 13px 20px;
  }
}
.courses__item-bottom-two .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 30px;
}
@media (max-width: 1199.98px) {
  .courses__item-bottom-two .list-wrap {
    gap: 10px 20px;
  }
}
.courses__item-bottom-two .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  color: var(--tg-common-color-dark);
  gap: 7px;
}
.courses__item-bottom-two .list-wrap li i {
  color: var(--tg-common-color-gray-3);
  font-size: 20px;
  line-height: 0;
}
.courses__item-bottom-two .list-wrap li::before {
  content: "";
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #8C9AB4;
}
@media (max-width: 1199.98px) {
  .courses__item-bottom-two .list-wrap li::before {
    right: -12px;
  }
}
.courses__item-bottom-two .list-wrap li:last-child::before {
  display: none;
}
@media (max-width: 767.98px) {
  .courses__nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
}
.courses__nav .courses-button-prev {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  line-height: 0;
  font-size: 24px;
  border: 1.2px solid var(--tg-common-color-black-3);
  box-shadow: -3.6px 2.4px 0px 0px #23232B;
  position: absolute;
  left: -90px;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 1500px) {
  .courses__nav .courses-button-prev {
    left: -15px;
  }
}
@media (max-width: 767.98px) {
  .courses__nav .courses-button-prev {
    position: relative;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    left: 0;
  }
}
.courses__nav .courses-button-prev i {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.courses__nav .courses-button-prev:hover {
  box-shadow: none;
}
.courses__nav .courses-button-next {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  line-height: 0;
  font-size: 24px;
  border: 1.2px solid var(--tg-common-color-black-3);
  box-shadow: 3.6px 2.4px 0px 0px #23232B;
  position: absolute;
  right: -90px;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 1500px) {
  .courses__nav .courses-button-next {
    right: -15px;
  }
}
@media (max-width: 767.98px) {
  .courses__nav .courses-button-next {
    position: relative;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    right: 0;
  }
}
.courses__nav .courses-button-next:hover {
  box-shadow: none;
}
.courses-top-wrap {
  margin-bottom: 30px;
}
.courses-top-left p {
  margin-bottom: 0;
  font-size: 16px;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-common-color-dark);
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .courses-top-left p {
    margin-bottom: 15px;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .courses-top-left p {
    text-align: center;
    margin-bottom: 15px;
  }
}
.courses-top-right {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.courses-top-right .sort-by {
  font-size: 16px;
  font-family: var(--tg-heading-font-family);
  display: block;
  color: var(--tg-common-color-gray-3);
}
.courses-top-right-select {
  width: 145px;
  position: relative;
}
.courses-top-right-select select {
  background-color: transparent;
  border: 1px solid #DCDBE5;
  color: var(--tg-common-color-dark);
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  border-radius: 4px;
  outline: none;
  padding: 10px 32px 10px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  line-height: 1.2;
  height: 40px;
}
.courses-top-right-select::after {
  content: "\f078";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  right: 12px;
  font-size: 12px;
  color: var(--tg-theme-primary);
}
.courses__sidebar {
  margin-right: 20px;
}
@media (max-width: 1199.98px) {
  .courses__sidebar {
    margin: 0 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .courses__sidebar {
    margin: 80px 0 0;
  }
}
.courses-widget {
  background: var(--tg-common-color-gray);
  padding: 30px 30px;
  margin-bottom: 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.courses-widget:last-child {
  margin-bottom: 0;
}
.courses-widget ul li {
  margin-bottom: 15px;
}
.courses-widget ul li:last-child {
  margin-bottom: 0;
}
.courses-widget .form-check {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
  padding: 0;
  min-height: inherit;
}
.courses-widget .form-check-input {
  border-radius: 4px;
  border: 1px solid #9D9CAE;
  margin: 0 10px 0 0;
  width: 16px;
  height: 16px;
}
.courses-widget .form-check-input:checked {
  background-color: var(--tg-theme-primary);
}
.courses-widget .form-check-input:focus {
  box-shadow: none;
  border-color: var(--tg-theme-primary);
}
.courses-widget .form-check-label {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  color: var(--tg-body-color);
  user-select: none;
}
.courses-widget .form-check .rating {
  display: flex;
  align-items: center;
}
.courses-widget .form-check .rating ul {
  display: flex;
  align-items: center;
  line-height: 1;
  color: #F8BC24;
  gap: 4px;
}
.courses-widget .form-check .rating ul li {
  margin-bottom: 0;
}
.courses-widget .form-check .rating ul li.delete {
  color: #D7D7D7;
}
.courses-widget .form-check .rating span {
  color: #5A7093;
  margin-left: 5px;
}
.courses-widget .show-more {
  font-family: var(--tg-heading-font-family);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-top: 20px;
}
.courses-slider-active .slick-arrow {
  position: absolute;
  left: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-30px);
  -moz-transform: translateY(-50%) translateX(-30px);
  -ms-transform: translateY(-50%) translateX(-30px);
  -o-transform: translateY(-50%) translateX(-30px);
  transform: translateY(-50%) translateX(-30px);
  width: 50px;
  height: 50px;
  background: var(--tg-common-color-gray);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 10px 20px #DFDFDF;
  border-radius: 50%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  padding: 0 0;
  z-index: 2;
}
.courses-slider-active .slick-arrow:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.courses-slider-active .slick-arrow.slick-next {
  left: auto !important;
  right: -10px;
  -webkit-transform: translateY(-50%) translateX(30px);
  -moz-transform: translateY(-50%) translateX(30px);
  -ms-transform: translateY(-50%) translateX(30px);
  -o-transform: translateY(-50%) translateX(30px);
  transform: translateY(-50%) translateX(30px);
}
.courses-slider-active:hover .slick-arrow {
  -webkit-transform: translateY(-50%) translateX(0);
  -moz-transform: translateY(-50%) translateX(0);
  -ms-transform: translateY(-50%) translateX(0);
  -o-transform: translateY(-50%) translateX(0);
  transform: translateY(-50%) translateX(0);
  opacity: 1;
  visibility: visible;
}
.courses-slider-active .col {
  padding-left: 15px;
  padding-right: 15px;
}
.courses-slider-active .courses__item-two {
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  box-shadow: none;
  padding: 15px;
}
.courses-slider-active .courses__item-two-thumb .author {
  position: absolute;
  right: 15px;
  bottom: -25px;
  z-index: 2;
}
.courses-slider-active .courses__item-two-thumb .author img {
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
  border: 3px solid #FFFFFF;
  border-radius: 50px;
}
.courses-slider-active .courses__item-two-content {
  padding: 16px 7px 5px 7px;
}
.courses-slider-active .courses__item-bottom .price {
  font-size: 20px;
  margin: 0 20px 0 0;
}
.courses-slider-active .courses__item-bottom .price del {
  font-size: 15px;
  margin-right: 6px;
}
.courses__nav-tabs {
  gap: 10px;
  border: none;
  margin-left: 10px;
}
.courses__nav-tabs .nav-item {
  margin: 0 0;
}
.courses__nav-tabs .nav-item .nav-link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #6196EA;
  border-radius: 4px;
  color: var(--tg-theme-primary);
}
.courses__nav-tabs .nav-item .nav-link svg {
  flex: 0 0 auto;
}
.courses__nav-tabs .nav-item .nav-link.active {
  border-color: var(--tg-theme-primary);
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.courses__list-wrap .courses__item-two {
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  box-shadow: none;
  padding: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 25px;
}
@media (max-width: 767.98px) {
  .courses__list-wrap .courses__item-two {
    gap: 20px;
    flex-direction: column;
  }
}
.courses__list-wrap .courses__item-two-thumb {
  flex: 0 0 auto;
}
.courses__list-wrap .courses__item-two-thumb a {
  height: 100%;
}
.courses__list-wrap .courses__item-two-thumb img {
  width: 270px;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1199.98px) {
  .courses__list-wrap .courses__item-two-thumb img {
    width: 235px;
  }
}
@media (max-width: 991.98px) {
  .courses__list-wrap .courses__item-two-thumb img {
    width: 270px;
  }
}
@media (max-width: 767.98px) {
  .courses__list-wrap .courses__item-two-thumb img {
    width: 100%;
  }
}
.courses__list-wrap .courses__item-two-content {
  padding: 0 15px 0 0;
}
@media (max-width: 767.98px) {
  .courses__list-wrap .courses__item-two-content {
    padding: 0 7px 5px 7px;
  }
}
.courses__list-wrap .courses__item-two-content .title {
  font-size: 20px;
  margin: 0 0 10px;
}
.courses__list-wrap .courses__item-two-content p {
  line-height: 1.65;
}
.courses__list-wrap .courses__item-two-content .courses__item-rating {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 6px;
}
.courses__list-wrap .courses__item-two-content .courses__item-rating i {
  font-size: 15px;
  margin: 0 0;
}
.courses__list-wrap .courses__item-two-content .courses__item-rating .rating-count {
  margin: 0 0;
}
.courses__list-wrap .courses__item-two-content .courses__item-tag {
  margin: 0 0 8px;
  font-size: 14px;
}
.courses__list-wrap .courses__item-two-content .courses__item-meta {
  margin: 0 0 14px;
}
.courses__list-wrap .courses__item-bottom {
  padding: 12px 0 0;
  margin: 0 0 0;
}
.courses__list-wrap .courses__item-bottom .author img {
  max-width: 35px;
}
.courses__details-thumb {
  margin-bottom: 30px;
}
.courses__details-thumb img {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  min-height: 250px;
  object-fit: cover;
}
.courses__details-content .courses__item-meta {
  justify-content: flex-start;
  gap: 15px;
}
.courses__details-content .courses__item-meta .avg-rating {
  color: #7F7E97;
  font-size: 14px;
  line-height: 1;
}
.courses__details-content .courses__item-meta .avg-rating i {
  color: var(--tg-common-color-yellow);
}
.courses__details-content .title {
  margin-bottom: 15px;
  font-size: 30px;
}
@media (max-width: 767.98px) {
  .courses__details-content .title {
    font-size: 26px;
  }
}
.courses__details-content .nav-tabs {
  border: none;
  gap: 12px;
  margin-bottom: 40px;
}
.courses__details-content .nav-tabs .nav-link {
  background: #E6E9EF;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  border: none;
  color: var(--tg-body-color);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  padding: 14px 30px;
  line-height: 1;
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.courses__details-content .nav-tabs .nav-link:hover, .courses__details-content .nav-tabs .nav-link.active {
  color: var(--tg-common-color-white);
  background: var(--tg-theme-primary);
  -webkit-box-shadow: 4px 6px 0px 0px #050071;
  -moz-box-shadow: 4px 6px 0px 0px #050071;
  -ms-box-shadow: 4px 6px 0px 0px #050071;
  -o-box-shadow: 4px 6px 0px 0px #050071;
  box-shadow: 4px 6px 0px 0px #050071;
}
.courses__details-meta .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;
  margin-bottom: 45px;
}
.courses__details-meta .list-wrap li {
  color: #7F7E97;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
}
.courses__details-meta .list-wrap li::before {
  content: "";
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: #8C9AB4;
}
.courses__details-meta .list-wrap li:last-child::before {
  display: none;
}
.courses__details-meta .list-wrap li i {
  font-size: 20px;
}
.courses__details-meta .list-wrap .author-two {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 5px;
}
.courses__details-meta .list-wrap .author-two img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-right: 5px;
}
.courses__details-meta .list-wrap .author-two a {
  color: var(--tg-heading-color);
}
.courses__details-sidebar {
  background: var(--tg-common-color-white);
  border: 1px solid #DFDFDF;
  -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  margin-left: 20px;
  padding: 30px 30px 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 1500px) {
  .courses__details-sidebar {
    margin-left: 0;
    padding: 30px 20px 40px;
  }
}
@media (max-width: 991.98px) {
  .courses__details-sidebar {
    padding: 30px 30px 40px;
    margin: 80px 0 0;
  }
}
.courses__details-social {
  border-bottom: 1px solid #DFDFDF;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.courses__details-social .title {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
}
.courses__details-social .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
.courses__details-social .list-wrap li a {
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E6E9EF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--tg-body-color);
}
.courses__details-social .list-wrap li a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.courses__details-enroll .tg-button-wrap {
  justify-content: center;
}
.courses__overview-wrap {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--tg-common-color-white);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 35px 40px 35px;
}
@media (max-width: 1199.98px) {
  .courses__overview-wrap {
    padding: 30px 30px 30px;
  }
}
.courses__overview-wrap .title {
  margin-bottom: 12px;
  font-size: 24px;
}
.courses__overview-wrap > p {
  margin-bottom: 20px;
}
.courses__overview-wrap > p.last-info {
  margin-bottom: 0;
}
.courses__overview-wrap .about__info-list {
  margin-bottom: 15px;
}
.courses__curriculum-wrap {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--tg-common-color-white);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 35px 40px 35px;
}
@media (max-width: 1199.98px) {
  .courses__curriculum-wrap {
    padding: 30px 30px 30px;
  }
}
.courses__curriculum-wrap .title {
  margin-bottom: 12px;
  font-size: 24px;
}
.courses__curriculum-wrap > p {
  margin-bottom: 20px;
}
.courses__curriculum-wrap .accordion-item {
  margin: 0;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  overflow: hidden;
}
.courses__curriculum-wrap .accordion-item:last-child {
  margin: 0 0;
}
.courses__curriculum-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.courses__curriculum-wrap .accordion-button {
  border: none;
  box-shadow: none;
  font-size: 20px;
  color: var(--tg-heading-color);
  padding: 16px 0 16px 0;
  font-weight: 500;
  border-bottom: 1px solid #DCDCEB;
}
.courses__curriculum-wrap .accordion-button::after {
  content: "\f067";
  font-family: var(--tg-icon-font-family);
  font-weight: 700;
  background-image: none;
  width: auto;
  height: auto;
}
.courses__curriculum-wrap .accordion-button:not(.collapsed) {
  background: transparent;
  color: var(--tg-theme-primary);
}
.courses__curriculum-wrap .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.courses__curriculum-wrap .accordion-body {
  padding: 0 0;
}
.courses__curriculum-wrap .course-item {
  padding: 13px 15px;
  background: #F8F8FF;
  margin-bottom: 2px;
}
@media (max-width: 767.98px) {
  .courses__curriculum-wrap .course-item {
    padding: 17px 20px;
  }
}
.courses__curriculum-wrap .course-item:last-child {
  margin-bottom: 0;
}
.courses__curriculum-wrap .course-item .course-item-link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: var(--tg-common-color-dark);
  cursor: no-drop;
}
.courses__curriculum-wrap .course-item .course-item-link:hover {
  color: var(--tg-theme-primary);
}
.courses__curriculum-wrap .course-item .item-name {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
}
@media (max-width: 767.98px) {
  .courses__curriculum-wrap .course-item .item-name {
    line-height: 1.4;
  }
}
.courses__curriculum-wrap .course-item .item-name::before {
  content: "";
  display: block;
  background-image: url(../img/icons/play.svg);
  width: 30px;
  height: 30px;
  flex: 0 0 auto;
}
.courses__curriculum-wrap .course-item-meta {
  margin-left: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  line-height: 1;
  gap: 20px;
  flex: 0 0 auto;
}
.courses__curriculum-wrap .course-item-meta .duration {
  margin: 2px 0 0;
}
.courses__curriculum-wrap .course-item.open-item .course-item-link {
  cursor: pointer;
}
.courses__instructors-wrap {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--tg-common-color-white);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 35px 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 30px;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .courses__instructors-wrap {
    padding: 30px 30px 30px;
  }
}
@media (max-width: 767.98px) {
  .courses__instructors-wrap {
    flex-direction: column;
  }
}
.courses__instructors-thumb {
  width: 225px;
  flex: 0 0 auto;
}
.courses__instructors-thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.courses__instructors-content .title {
  margin-bottom: 5px;
  font-size: 20px;
}
.courses__instructors-content > p {
  margin-bottom: 15px;
}
.courses__instructors-content .designation {
  display: block;
  color: var(--tg-theme-primary);
  line-height: 1;
  margin-bottom: 6px;
}
.courses__instructors-content .avg-rating {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: var(--tg-common-color-gray-3);
  font-size: 15px;
  gap: 8px;
  margin-bottom: 8px;
}
.courses__instructors-content .avg-rating i {
  color: var(--tg-common-color-yellow);
}
.courses__rating-wrap {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--tg-common-color-white);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 35px 40px 70px 40px;
}
@media (max-width: 1199.98px) {
  .courses__rating-wrap {
    padding: 30px 30px 50px 30px;
  }
}
.courses__rating-wrap .title {
  margin-bottom: 30px;
  font-size: 24px;
}
.courses__cost-wrap {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  background: var(--tg-theme-primary);
  -webkit-box-shadow: 0px 3px 15px 0px rgba(13, 9, 99, 0.26);
  -moz-box-shadow: 0px 3px 15px 0px rgba(13, 9, 99, 0.26);
  -ms-box-shadow: 0px 3px 15px 0px rgba(13, 9, 99, 0.26);
  -o-box-shadow: 0px 3px 15px 0px rgba(13, 9, 99, 0.26);
  box-shadow: 0px 3px 15px 0px rgba(13, 9, 99, 0.26);
  padding: 20px 20px 20px;
  margin-bottom: 20px;
}
.courses__cost-wrap span {
  display: block;
  font-weight: 500;
  color: var(--tg-common-color-white);
  line-height: 1;
  margin-bottom: 10px;
}
.courses__cost-wrap .title {
  margin-bottom: 0;
  font-size: 30px;
  color: var(--tg-common-color-white);
  line-height: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 5px;
}
.courses__cost-wrap .title del {
  font-size: 20px;
  font-weight: 500;
  color: #9490FA;
}
.courses__information-wrap .title {
  font-size: 16px;
  margin-bottom: 15px;
}
.courses__information-wrap .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: var(--tg-common-color-dark);
  gap: 15px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 15px;
  padding-bottom: 15px;
  line-height: 1;
}
.courses__information-wrap .list-wrap li span {
  display: block;
  margin-left: auto;
  color: var(--tg-common-color-gray-3);
}
.courses__payment {
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 25px;
  padding-bottom: 30px;
}
.courses__payment .title {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
}

.widget-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.tab-content {
  position: relative;
}

.course-rate {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 36px;
}
@media (max-width: 767.98px) {
  .course-rate {
    flex-direction: column;
  }
}
.course-rate__summary {
  background: var(--tg-common-color-white);
  border: 1px solid #E6EAEF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  width: 180px;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 33px 20px;
  gap: 3px;
}
.course-rate__summary-value {
  color: #082A5E;
  font-weight: 600;
  font-size: 64px;
  line-height: 0.8;
}
.course-rate__summary-stars {
  font-size: 15px;
  color: #F8BC24;
}
.course-rate__summary-text {
  color: #5A7093;
}
.course-rate__details {
  flex-grow: 1;
}
@media (max-width: 767.98px) {
  .course-rate__details {
    width: 100%;
  }
}
.course-rate__details-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 10px;
  margin: 0 0 17px;
}
.course-rate__details-row:last-child {
  margin: 0 0;
}
.course-rate__details-row-star {
  font-size: 20px;
}
.course-rate__details-row-star i {
  font-size: 17px;
  color: #F8BC24;
  margin-left: 3px;
}
.course-rate__details-row-value {
  position: relative;
  margin-right: 20px;
  flex-grow: 1;
}
.course-rate__details-row-value .rating-gray {
  background: #E0E3EB;
  border-radius: 50px;
  height: 7px;
}
.course-rate__details-row-value .rating {
  position: absolute;
  left: 0;
  top: 0;
  height: 7px;
  background: #F8BC24;
  border-radius: 50px;
}
.course-rate__details-row-value .rating-count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  font-size: 20px;
}

.course-review-head {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  border-top: 1px solid #DFDFDF;
  padding-top: 55px;
  margin-top: 60px;
  gap: 20px;
}
@media (max-width: 767.98px) {
  .course-review-head {
    flex-wrap: wrap;
  }
}
.course-review-head .review-author-thumb {
  width: 80px;
  flex: 0 0 auto;
}
.course-review-head .review-author-thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.course-review-head .review-author-content .author-name {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 20px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.course-review-head .review-author-content .author-name .name {
  margin-bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  color: var(--tg-body-color);
}
.course-review-head .review-author-content .author-name .name span {
  font-size: 14px;
  font-weight: 400;
  color: var(--tg-common-color-gray-3);
}
.course-review-head .review-author-content .author-name .author-rating {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--tg-common-color-yellow);
}
.course-review-head .review-author-content .title {
  margin-bottom: 12px;
  font-size: 18px;
}
.course-review-head .review-author-content p {
  margin-bottom: 0;
}

.shine__animate-link {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.shine__animate-link::before {
  position: absolute;
  top: 0;
  left: -100%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.shine__animate-item:hover .shine__animate-link::before {
  -webkit-animation: hoverShine 1.2s;
  animation: hoverShine 1.2s;
}

/*=============================
    09. Categories
===============================*/
.section-py-120 {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .section-py-120 {
    padding: 100px 0;
  }
}

.categories__wrap {
  background: var(--tg-common-color-gray);
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  -o-border-radius: 500px;
  -ms-border-radius: 500px;
  border-radius: 500px;
  padding: 52px 145px 45px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .categories__wrap {
    padding: 52px 100px 45px;
  }
}
@media (max-width: 991.98px) {
  .categories__wrap {
    padding: 45px 90px 45px;
  }
}
@media (max-width: 767.98px) {
  .categories__wrap {
    padding: 30px 20px 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }
}
.categories__item {
  text-align: center;
  display: flex;
}
.categories__item .icon {
  width: 150px;
  height: 150px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--tg-border-1);
  border-radius: 150px;
  background: linear-gradient(180deg, var(--tg-common-color-white) 0%, #F1F1FA 100%);
  font-size: 55px;
  color: #7F7E97;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  margin: 0 0 12px;
}
@media (max-width: 767.98px) {
  .categories__item .icon {
    width: 130px;
    height: 130px;
    font-size: 45px;
    margin: 0 auto 12px;
  }
}
.categories__item .icon::before {
  content: "";
  position: absolute;
  top: 26px;
  right: 26px;
  /* background-image: url(../img/icons/cat_star.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 26px;
  pointer-events: none;
}
.categories__item .name {
  display: block;
  font-family: var(--tg-heading-font-family);
  font-size: 15px;
  font-weight: var(--tg-fw-medium);
  line-height: 1.5;
  color: var(--tg-heading-color);
  margin: 0 0 7px;
}
.categories__item .courses {
  display: block;
  line-height: 1.3;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-body-color);
}
.categories__item:hover .icon {
  /* background: var(--tg-theme-primary); */
  box-shadow: 6px 5px 0px 0px rgba(0, 0, 0, 0.25);
  /* color: var(--tg-common-color-white); */
}

@media (max-width: 767.98px) {
  .categories__nav {
    display: none;
  }
}
.categories__nav button {
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: var(--tg-theme-secondary);
  padding: 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #333;
  box-shadow: -3px 2px 0px 0px #382900;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .categories__nav button {
    left: 30px;
  }
}
@media (max-width: 1199.98px) {
  .categories__nav button {
    left: 20px;
  }
}
.categories__nav button.categories-button-next {
  left: auto;
  right: 50px;
  box-shadow: 2px 4px 0px 0px #382900;
}
@media (max-width: 1199.98px) {
  .categories__nav button.categories-button-next {
    right: 30px;
  }
}
@media (max-width: 1199.98px) {
  .categories__nav button.categories-button-next {
    right: 20px;
  }
}
.categories__nav button:hover {
  box-shadow: none;
}

/*=============================
    11. Testimonial
===============================*/
.section-pb-57 {
  padding-bottom: 57px;
}

.testimonial__item {
  background: #F6F5FE;
  padding: 40px 45px 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .testimonial__item {
    padding: 30px 20px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial__item {
    padding: 30px 30px 30px;
  }
}
.testimonial__item-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.testimonial__item-wrap {
  position: relative;
}
.testimonial__item::before {
  content: "\f10e";
  font-size: 48px;
  line-height: 1;
  color: #D9D6F5;
  font-family: var(--tg-icon-font-family);
  position: absolute;
  right: 43px;
  top: 45px;
  font-weight: 700;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .testimonial__item::before {
    top: 30px;
    right: 20px;
    font-size: 45px;
  }
}
.testimonial__author {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (max-width: 767.98px) {
  .testimonial__author {
    gap: 15px;
  }
}
.testimonial__author-thumb img {
  border: 3px solid #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.testimonial__author-content .rating {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 15px;
  color: var(--tg-common-color-yellow);
  margin-bottom: 10px;
}
.testimonial__author-content .title {
  font-size: 20px;
  margin-bottom: 0;
}
.testimonial__icon {
  font-size: 48px;
  line-height: 0;
  color: #D9D6F5;
}
.testimonial__content p {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .testimonial__nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
  }
}
.testimonial__nav button {
  padding: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid var(--tg-common-color-dark);
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  font-size: 20px;
  line-height: 0;
  -webkit-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  -moz-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  -ms-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  -o-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  box-shadow: 3.6px 2.4px 0px 0px #23232B;
  position: absolute;
  right: -75px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
}
@media (max-width: 1500px) {
  .testimonial__nav button {
    right: -20px;
  }
}
@media (max-width: 767.98px) {
  .testimonial__nav button {
    position: inherit;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
.testimonial__nav button.testimonial-button-prev {
  right: auto;
  left: -75px;
  -webkit-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  -moz-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  -ms-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  -o-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  box-shadow: -3.6px 2.4px 0px 0px #23232B;
}
@media (max-width: 1500px) {
  .testimonial__nav button.testimonial-button-prev {
    left: -20px;
  }
}
.testimonial__nav button.testimonial-button-prev i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.testimonial__nav button:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.swiper-container {
  overflow: hidden;
}

/*=============================
    12. Instructor
===============================*/
.instructor__area {
  padding: 120px 0 85px;
}
@media (max-width: 767.98px) {
  .instructor__area {
    padding: 100px 0 65px;
  }
}
.instructor__area-two {
  background: var(--tg-common-color-blue-2);
}
.instructor__area-four {
  background: var(--tg-common-color-gray);
}
.instructor__bg {
  background-size: cover;
  background-position: center;
  padding: 265px 0 120px;
  margin-top: -145px;
  position: relative;
  z-index: 1;
}
.instructor__content-wrap {
  width: 87%;
}
@media (max-width: 1199.98px) {
  .instructor__content-wrap {
    width: 100%;
    margin-bottom: 50px;
  }
}
.instructor__content-wrap p {
  margin-bottom: 20px;
}
.instructor__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 35px;
}
@media (max-width: 991.98px) {
  .instructor__item {
    justify-content: center;
    display: block;
    text-align: center;
  }
}
.instructor__item-two {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 30px;
  align-items: center;
  border: 1px solid #BDBDBD;
  background: var(--tg-common-color-white);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -o-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
  padding: 0 60px 0 25px;
  position: relative;
  z-index: 3;
  overflow: hidden;
  min-height: 260px;
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .instructor__item-two {
    padding: 0 35px 0 25px;
  }
}
@media (max-width: 767.98px) {
  .instructor__item-two {
    min-height: auto;
    padding: 30px;
  }
}
.instructor__item-two:hover .instructor__thumb-two .shape-one {
  color: var(--tg-theme-primary);
}
.instructor__item-three {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  .instructor__item-three {
    flex-wrap: wrap;
  }
}
.instructor__thumb {
  position: relative;
  width: 186px;
  flex: 0 0 auto;
}
@media (max-width: 991.98px) {
  .instructor__thumb {
    margin: 0 auto 20px;
  }
}
.instructor__thumb::before {
  content: "";
  position: absolute;
  width: 180px;
  height: 180px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  background: linear-gradient(156deg, #F7F6F9 10.62%, #E9F5F5 90.16%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
}
.instructor__thumb-two {
  width: auto;
  flex: 0 0 auto;
  margin-top: auto;
}
@media (max-width: 767.98px) {
  .instructor__thumb-two {
    display: none;
  }
}
.instructor__thumb-two .shape-one {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  color: var(--tg-theme-secondary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.instructor__thumb-two .shape-two {
  position: absolute;
  left: 34px;
  top: 5px;
  z-index: -1;
  width: 40px;
  height: 48px;
}
.instructor__thumb-two .shape-two .svg-icon {
  width: 100%;
  height: 100%;
  display: block;
}
.instructor__thumb-two .shape-two .svg-icon path {
  stroke: var(--tg-theme-secondary);
}
.instructor__thumb-three {
  width: 50%;
  flex: 0 0 auto;
  text-align: center;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 453px;
}
@media (max-width: 767.98px) {
  .instructor__thumb-three {
    width: 100%;
  }
}
.instructor__thumb-three .shape-one {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  z-index: -1;
  color: var(--tg-theme-secondary);
}
@media (max-width: 991.98px) {
  .instructor__thumb-three .shape-one {
    width: 100%;
  }
  .instructor__thumb-three .shape-one svg {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instructor__thumb-three .shape-one {
    width: 400px;
  }
}
.instructor__content .title {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}
.instructor__content .designation {
  display: block;
  color: var(--tg-theme-primary);
  font-family: var(--tg-heading-font-family);
  line-height: 1.2;
  margin-bottom: 12px;
}
.instructor__content .avg-rating {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--tg-common-color-gray-3);
  gap: 5px;
  margin-bottom: 15px;
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .instructor__content .avg-rating {
    justify-content: center;
  }
}
.instructor__content .avg-rating i {
  color: var(--tg-common-color-yellow);
}
.instructor__content-two .title {
  margin-bottom: 5px;
  font-size: 28px;
}
@media (max-width: 1199.98px) {
  .instructor__content-two .title {
    font-size: 26px;
  }
}
@media (max-width: 767.98px) {
  .instructor__content-two .title {
    font-size: 24px;
  }
}
.instructor__content-two p {
  margin-bottom: 20px;
}
.instructor__content-three {
  margin-left: 40px;
  margin-top: 40px;
}
@media (max-width: 767.98px) {
  .instructor__content-three {
    margin-left: 0;
  }
}
.instructor__content-three .ratting {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: var(--tg-common-color-yellow);
  gap: 3px;
  line-height: 1;
  font-size: 15px;
}
.instructor__content-three .ratting-wrap {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--tg-common-color-gray-3);
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -o-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
  background: var(--tg-common-color-white);
  padding: 7px 10px;
  gap: 8px;
  margin-bottom: 20px;
}
.instructor__content-three .ratting-wrap span {
  font-size: 14px;
  color: var(--tg-common-color-gray-3);
  line-height: 1;
}
.instructor__content-three .title {
  margin-bottom: 10px;
  font-size: 24px;
}
.instructor__content-three .designation {
  display: block;
  line-height: 1;
  font-size: 18px;
  font-weight: 400;
  color: var(--tg-theme-primary);
  font-family: var(--tg-heading-font-family);
  margin-bottom: 15px;
}
.instructor__content-three p {
  margin-bottom: 22px;
  width: 80%;
}
@media (max-width: 991.98px) {
  .instructor__content-three p {
    width: 100%;
  }
}
.instructor__content-three .instructor__social {
  margin-bottom: 35px;
}
@media (max-width: 991.98px) {
  .instructor__content-three .instructor__social .list-wrap {
    justify-content: flex-start;
  }
}
.instructor__content-three .instructor__social .list-wrap li a {
  width: 50px;
  height: 50px;
  font-size: 20px;
}
.instructor__social .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
}
@media (max-width: 991.98px) {
  .instructor__social .list-wrap {
    justify-content: center;
  }
}
.instructor__social .list-wrap li a {
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.835px solid #9292B4;
  background: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 2.50435px 3.33913px 0px 0px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  color: var(--tg-common-color-gray-3);
}
.instructor__social .list-wrap li a:hover {
  background: var(--tg-theme-primary);
  box-shadow: none;
  border-color: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.instructor-nav .swiper-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 30px;
}
.instructor-nav .swiper-wrapper button {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  width: 100px;
  height: 100px;
  background: #D9D9D9;
  border: 2px solid #ABABAB;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  filter: grayscale(1);
  line-height: 1;
}
.instructor-nav .swiper-wrapper .swiper-slide {
  width: auto !important;
}
.instructor-nav .swiper-slide-thumb-active button {
  filter: grayscale(0);
  background: var(--tg-common-color-white);
  border-color: var(--tg-theme-primary);
}
.instructor-active {
  overflow: hidden;
}
.instructor-slider-dot {
  text-align: center;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -o-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
  border: 1px solid #D0D0D0;
  background: var(--tg-common-color-white);
  padding: 40px 120px;
  margin: 0 25px;
  position: relative;
  height: 190px;
}
@media (max-width: 1500px) {
  .instructor-slider-dot {
    margin: 0 0;
  }
}
@media (max-width: 1199.98px) {
  .instructor-slider-dot {
    padding: 40px 85px;
  }
}
@media (max-width: 767.98px) {
  .instructor-slider-dot {
    padding: 40px 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }
}
@media (max-width: 767.98px) {
  .instructor-slider-dot {
    padding: 40px 75px;
  }
}
@media (max-width: 767.98px) {
  .instructor__nav-two {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instructor__nav-two {
    display: block;
  }
}
.instructor__nav-two .instructor-button-next, .instructor__nav-two .instructor-button-prev {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-secondary);
  border: 2px solid #333;
  position: absolute;
  left: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: var(--tg-common-color-black);
  -webkit-box-shadow: 2px 4px 0px 0px #382900;
  -moz-box-shadow: 2px 4px 0px 0px #382900;
  -ms-box-shadow: 2px 4px 0px 0px #382900;
  -o-box-shadow: 2px 4px 0px 0px #382900;
  box-shadow: 2px 4px 0px 0px #382900;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .instructor__nav-two .instructor-button-next, .instructor__nav-two .instructor-button-prev {
    left: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instructor__nav-two .instructor-button-next, .instructor__nav-two .instructor-button-prev {
    right: 15px;
  }
}
.instructor__nav-two .instructor-button-next i, .instructor__nav-two .instructor-button-prev i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.instructor__nav-two .instructor-button-next:hover, .instructor__nav-two .instructor-button-prev:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.instructor__nav-two .instructor-button-next {
  left: auto;
  right: 40px;
}
@media (max-width: 1199.98px) {
  .instructor__nav-two .instructor-button-next {
    right: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instructor__nav-two .instructor-button-next {
    right: 15px;
  }
}
.instructor__nav-two .instructor-button-next i {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.instructor__shape img {
  position: absolute;
  right: 24%;
  top: 40%;
  z-index: -1;
}
@media (max-width: 1500px) {
  .instructor__shape img {
    right: 17%;
  }
}
@media (max-width: 1199.98px) {
  .instructor__shape img {
    right: 10%;
  }
}
@media (max-width: 991.98px) {
  .instructor__shape img {
    right: 4%;
    width: 100px;
    top: 32%;
  }
}
.instructor__details-info {
  background: var(--tg-common-color-dark);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 50px 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 45px;
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .instructor__details-info {
    padding: 35px 30px;
    gap: 25px;
  }
}
@media (max-width: 767.98px) {
  .instructor__details-info {
    padding: 25px 25px;
    flex-direction: column;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instructor__details-info {
    padding: 35px 30px;
  }
}
.instructor__details-thumb {
  width: 250px;
  flex: 0 0 auto;
}
.instructor__details-thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.instructor__details-content .title {
  margin-bottom: 8px;
  font-size: 24px;
  color: var(--tg-common-color-white);
}
.instructor__details-content .designation {
  display: block;
  font-size: 16px;
  color: var(--tg-common-color-white);
  font-family: var(--tg-heading-font-family);
  line-height: 1;
  margin-bottom: 22px;
}
.instructor__details-content > .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px 30px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.instructor__details-content > .list-wrap > li {
  color: #EFEEFE;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  position: relative;
}
.instructor__details-content > .list-wrap > li::before {
  content: "";
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-common-color-white);
}
.instructor__details-content > .list-wrap > li:last-child::before {
  display: none;
}
.instructor__details-content > .list-wrap > li i {
  font-size: 20px;
  line-height: 1;
}
.instructor__details-content > .list-wrap > li a {
  color: #EFEEFE;
}
.instructor__details-content > .list-wrap > li a:hover {
  color: var(--tg-theme-secondary);
}
.instructor__details-content > .list-wrap > li.avg-rating {
  font-size: 14px;
  gap: 5px;
}
.instructor__details-content > .list-wrap > li.avg-rating i {
  color: var(--tg-common-color-yellow);
  font-size: 14px;
}
.instructor__details-content p {
  margin-bottom: 25px;
  color: var(--tg-common-color-white);
}
.instructor__details-social .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.instructor__details-social .list-wrap li a {
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.instructor__details-social .list-wrap li a:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-heading-color);
}
.instructor__details-biography {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--tg-common-color-white);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 35px 40px;
  margin-bottom: 30px;
}
@media (max-width: 767.98px) {
  .instructor__details-biography {
    padding: 30px 25px;
  }
}
.instructor__details-biography .title {
  margin-bottom: 14px;
  font-size: 24px;
}
.instructor__details-biography p {
  margin-bottom: 8px;
}
.instructor__details-biography p:last-child {
  margin-bottom: 0;
}
.instructor__details-Skill {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--tg-common-color-white);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 35px 40px 25px;
  margin-bottom: 45px;
}
@media (max-width: 767.98px) {
  .instructor__details-Skill {
    padding: 30px 25px 15px;
  }
}
.instructor__details-Skill .title {
  margin-bottom: 14px;
  font-size: 24px;
}
.instructor__details-Skill p {
  margin-bottom: 18px;
}
.instructor__details-courses .main-title {
  margin-bottom: 5px;
  font-size: 30px;
}
.instructor__details-courses p {
  margin-bottom: 0;
}
.instructor__details-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
@media (max-width: 767.98px) {
  .instructor__details-nav {
    margin-top: 25px;
    justify-content: flex-start;
  }
}
.instructor__details-nav button {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid var(--tg-common-color-dark);
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  font-size: 20px;
  -webkit-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  -moz-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  -ms-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  -o-box-shadow: 3.6px 2.4px 0px 0px #23232B;
  box-shadow: 3.6px 2.4px 0px 0px #23232B;
}
.instructor__details-nav button.courses-button-prev {
  -webkit-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  -moz-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  -ms-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  -o-box-shadow: -3.6px 2.4px 0px 0px #23232B;
  box-shadow: -3.6px 2.4px 0px 0px #23232B;
}
.instructor__details-nav button.courses-button-prev i {
  transform: rotate(180deg);
}
.instructor__details-nav button:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.instructor__progress-wrap .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -28px;
}
@media (max-width: 767.98px) {
  .instructor__progress-wrap .list-wrap {
    margin: 0 -10px;
  }
}
.instructor__progress-wrap .list-wrap li {
  width: 50%;
  flex: 0 0 auto;
  padding: 0 28px;
}
@media (max-width: 767.98px) {
  .instructor__progress-wrap .list-wrap li {
    padding: 0 10px;
    width: 100%;
  }
}
.instructor__sidebar {
  background: var(--tg-common-color-white);
  border: 1px solid #DFDFDF;
  -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 25px 35px;
  margin-left: 20px;
}
@media (max-width: 1199.98px) {
  .instructor__sidebar {
    margin-left: 0;
    margin-top: 80px;
  }
}
.instructor__sidebar .title {
  font-size: 20px;
  margin-bottom: 10px;
}
.instructor__sidebar p {
  margin-bottom: 15px;
}
.instructor__sidebar .form-grp {
  margin-bottom: 8px;
}
.instructor__sidebar .form-grp textarea, .instructor__sidebar .form-grp input {
  border: none;
  background: #F4F3F8;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 20px;
  color: var(--tg-heading-color);
  height: 45px;
}
.instructor__sidebar .form-grp textarea::placeholder, .instructor__sidebar .form-grp input::placeholder {
  font-size: 14px;
  color: #6D6C80;
}
.instructor__sidebar .form-grp textarea {
  min-height: 112px;
  max-height: 112px;
}
.instructor__sidebar .btn {
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.progress-item {
  margin-bottom: 20px;
}
.progress-item .title {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.progress-item .title span {
  margin-left: auto;
}
.progress-item .progress {
  height: 10px;
  background: #EBEBEB;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.progress-item .progress .progress-bar {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  background-color: var(--tg-theme-secondary);
}

/*=============================
    14. CTA
===============================*/
.cta__wrapper {
  background-image: url(../img/bg/cta_bg.html);
  background-color: var(--tg-theme-primary);
  background-size: cover;
  background-position: center;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  padding: 46px 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  column-gap: 40px;
  overflow: hidden;
  margin: 0 0 -85px;
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .cta__wrapper {
    flex-wrap: wrap;
    gap: 25px 70px;
  }
}
@media (max-width: 767.98px) {
  .cta__wrapper {
    padding: 45px 30px;
  }
}
.cta__wrapper .section__title {
  width: 26%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .cta__wrapper .section__title {
    width: 100%;
    flex: 0 0 auto;
  }
}
.cta__wrapper .section__title .title {
  font-size: 30px;
}
.cta__wrapper .object {
  position: absolute;
  z-index: -1;
}
.cta__desc {
  flex-grow: 1;
}
@media (max-width: 1199.98px) {
  .cta__desc {
    width: 60%;
    flex: 0 0 auto;
  }
}
@media (max-width: 991.98px) {
  .cta__desc {
    width: 100%;
    flex: 0 0 auto;
  }
}
.cta__desc p {
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-medium);
  margin: 0 0;
}
.cta-area-two {
  padding: 150px 0 160px;
  background-color: #072656;
  overflow: hidden;
  z-index: 1;
}
.cta__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
  background-attachment: fixed;
  opacity: 0.2;
  z-index: -1;
}
.cta__content {
  text-align: center;
}
.cta__content p {
  text-transform: uppercase;
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-medium);
  letter-spacing: 0.1em;
  margin: 0 0 13px;
}
.cta__content .title {
  color: var(--tg-common-color-white);
  font-size: 48px;
  margin: 0 0 8px;
}
@media (max-width: 767.98px) {
  .cta__content .title {
    font-size: 38px;
    margin: 0 0 12px;
  }
}
.cta__content .sub-title {
  font-size: 30px;
  font-weight: var(--tg-fw-light);
  color: var(--tg-common-color-white);
  margin: 0 0 40px;
}
@media (max-width: 767.98px) {
  .cta__content .sub-title {
    font-size: 22px;
  }
}
.cta__shapes > *:nth-child(1) {
  left: 0;
  top: 0;
  opacity: 0.1 !important;
}
@media (max-width: 1199.98px) {
  .cta__shapes > *:nth-child(1) {
    max-width: 220px;
  }
}
.cta__shapes > *:nth-child(2) {
  top: 31%;
  left: 10%;
  max-width: 125px;
}
@media (max-width: 1199.98px) {
  .cta__shapes > *:nth-child(2) {
    top: 20%;
    left: 5%;
    max-width: 100px;
  }
}
@media (max-width: 767.98px) {
  .cta__shapes > *:nth-child(2) {
    top: 7%;
    max-width: 80px;
  }
}
.cta__shapes > *:nth-child(3) {
  right: 0;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .cta__shapes > *:nth-child(3) {
    max-width: 180px;
  }
}
@media (max-width: 767.98px) {
  .cta__shapes > *:nth-child(3) {
    max-width: 120px;
  }
}
.cta-area-three {
  position: relative;
  z-index: 3;
}
.cta-area-three::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: var(--tg-common-color-dark);
  z-index: -1;
}
@media (max-width: 991.98px) {
  .cta-area-three::before {
    height: 150px;
  }
}
.cta-area-three .cta__wrapper {
  margin: 0 0;
}

/*=============================
    15. Fact
===============================*/
.fact__inner-wrap {
  background: var(--tg-common-color-blue-2);
  padding: 94px 70px 64px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -o-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
  -webkit-box-shadow: 0px 25px 70px 0px rgba(40, 37, 104, 0.4);
  -moz-box-shadow: 0px 25px 70px 0px rgba(40, 37, 104, 0.4);
  -ms-box-shadow: 0px 25px 70px 0px rgba(40, 37, 104, 0.4);
  -o-box-shadow: 0px 25px 70px 0px rgba(40, 37, 104, 0.4);
  box-shadow: 0px 25px 70px 0px rgba(40, 37, 104, 0.4);
  z-index: 3;
  position: relative;
}
@media (max-width: 1199.98px) {
  .fact__inner-wrap {
    padding: 60px 40px 30px;
  }
}
.fact__inner-wrap .row [class*=col-]:last-child .fact__item::before {
  display: none;
}
.fact__inner-wrap-two {
  background: var(--tg-common-color-blue-2);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  padding: 85px 380px 60px 85px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 75px;
  position: relative;
  z-index: 3;
}
@media (max-width: 1500px) {
  .fact__inner-wrap-two {
    padding: 85px 360px 60px 70px;
    gap: 50px;
  }
}
@media (max-width: 1199.98px) {
  .fact__inner-wrap-two {
    padding: 70px 50px 50px 50px;
  }
}
@media (max-width: 991.98px) {
  .fact__inner-wrap-two {
    padding: 60px 40px 30px 40px;
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .fact__inner-wrap-two {
    padding: 60px 30px 30px 30px;
  }
}
.fact__inner-wrap-two .section__title {
  width: 46%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .fact__inner-wrap-two .section__title {
    width: 100%;
    text-align: center;
  }
}
.fact__item {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
.fact__item::before {
  content: "";
  position: absolute;
  right: -15px;
  top: -10%;
  width: 2px;
  height: 108px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 991.98px) {
  .fact__item::before {
    display: none;
  }
}
.fact__item .count {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tg-common-color-white);
  font-size: 56px;
  line-height: 0.84;
  font-family: var(--tg-body-font-family);
}
@media (max-width: 1199.98px) {
  .fact__item .count {
    font-size: 48px;
  }
}
@media (max-width: 767.98px) {
  .fact__item .count {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .fact__item .count {
    font-size: 36px;
  }
}
.fact__item p {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--tg-common-color-white);
  line-height: 1.2;
}
.fact__item-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 120px;
}
@media (max-width: 991.98px) {
  .fact__item-wrap {
    gap: 60px;
  }
}
@media (max-width: 767.98px) {
  .fact__item-wrap {
    gap: 30px;
  }
}
.fact__item-wrap .fact__item::before {
  right: -60px;
  top: -9%;
}
@media (max-width: 991.98px) {
  .fact__item-wrap .fact__item::before {
    display: none;
  }
}
.fact__item-wrap .fact__item:last-child::before {
  display: none;
}
.fact__img-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .fact__img-wrap {
    display: none;
  }
}
.fact__img-wrap img {
  right: 30px;
  position: relative;
}
.fact__img-wrap .shape-one {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.fact__img-wrap .shape-two .svg-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  left: -7px;
  top: -22px;
  z-index: -1;
}
.fact__img-wrap .shape-two .svg-icon svg {
  width: 100%;
  height: 100%;
}

/*=============================
    15. Features
===============================*/
.features__area {
  background: #082137;
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .features__area {
    padding: 100px 0 70px;
  }
}
.features__area-three {
  background: var(--tg-common-color-gray);
}
.features__item {
  text-align: center;
  margin-bottom: 30px;
}
.features__item:hover .features__icon {
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
  transform: translateY(5px);
}
.features__item-two {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #C9E4E9;
  background: #F1FDFF;
  box-shadow: 8px 8px 0px 0px #C9E4E9;
  padding: 40px 40px 40px 50px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .features__item-two {
    padding: 30px 25px 30px 25px;
  }
}
.features__item-two:hover {
  box-shadow: none !important;
}
.features__item-two:hover .features__item-shape {
  color: #1BCBE3;
}
.features__item-shape {
  position: absolute;
  right: 28px;
  top: 21px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: rgba(39, 39, 47, 0.2);
}
.features__item-wrap .row [class*=col-]:nth-child(2) .features__item-two {
  border: 1px solid #C8C1ED;
  background: #EDEAFF;
  box-shadow: 8px 8px 0px 0px #D9D5F1;
}
.features__item-wrap .row [class*=col-]:nth-child(2) .features__item-two .features__icon-two {
  background: #5751E1;
}
.features__item-wrap .row [class*=col-]:nth-child(2) .features__item-two:hover .features__item-shape {
  color: #5751E1;
}
.features__item-wrap .row [class*=col-]:nth-child(3) .features__item-two {
  border: 1px solid #EBE0C4;
  background: #FFF7E2;
  box-shadow: 8px 8px 0px 0px #E5DECB;
}
.features__item-wrap .row [class*=col-]:nth-child(3) .features__item-two .features__icon-two {
  background: #FFC224;
}
.features__item-wrap .row [class*=col-]:nth-child(3) .features__item-two:hover .features__item-shape {
  color: #FFC224;
}
.features__icon {
  margin-bottom: 22px;
  min-height: 93px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.features__icon-two {
  width: 70px;
  height: 70px;
  flex: 0 0 auto;
  color: var(--tg-common-color-white);
  background: #1BCBE3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features__content .title {
  color: var(--tg-common-color-white);
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: var(--tg-fw-semi-bold);
}
.features__content p {
  margin-bottom: 0;
  /* color: var(--tg-common-color-gray-4); */
  color:#fff;
}
.features__content-two .content-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 18px;
}
.features__content-two .title {
  margin-bottom: 0;
  font-size: 22px;
}
.features__content-two p {
  margin-bottom: 0;
  color: var(--tg-common-color-dark);
  text-transform: capitalize;
}

/*=============================
    16. Events
===============================*/
.event__area {
  position: relative;
  z-index: 1;
}
.event__area::before {
  content: "";
  position: absolute;
  right: 18%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 790px;
  height: 430px;
  background: #F2F4F6;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  -o-border-radius: 80px;
  -ms-border-radius: 80px;
  border-radius: 80px;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .event__area::before {
    right: 10%;
  }
}
@media (max-width: 991.98px) {
  .event__area::before {
    display: none;
  }
}
.event__inner-wrap .row .col-30 {
  width: 30%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .event__inner-wrap .row .col-30 {
    width: 100%;
  }
}
.event__inner-wrap .row .col-70 {
  width: 70%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .event__inner-wrap .row .col-70 {
    width: 100%;
  }
}
.event__content {
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .event__content {
    text-align: center;
    margin-bottom: 50px;
  }
  .event__content .tg-button-wrap {
    justify-content: center;
  }
}
.event__content p {
  margin-bottom: 25px;
}
.event__item {
  border: 1px solid #B5B5C3;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 20px 20px 20px 25px;
  background: var(--tg-common-color-white);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.event__item:hover {
  -webkit-box-shadow: 10px 10px 0px #CAC9D6;
  -moz-box-shadow: 10px 10px 0px #CAC9D6;
  -ms-box-shadow: 10px 10px 0px #CAC9D6;
  -o-box-shadow: 10px 10px 0px #CAC9D6;
  box-shadow: 10px 10px 0px #CAC9D6;
}
.event__item-thumb {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
}
.event__item-thumb img {
  width: 100%;
  height: 212px;
  object-fit: cover;
}
.event__item-content {
  position: relative;
  padding: 30px 0 0;
}
.event__item-content .date {
  font-size: 14px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
  background: var(--tg-theme-secondary);
  display: block;
  line-height: 1;
  border: 1px solid var(--tg-heading-color);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  padding: 8px 13px;
  position: absolute;
  left: 0;
  top: -20px;
  z-index: 2;
  -webkit-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -moz-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -ms-box-shadow: 4px 4px 0px 0px #3D3D3D;
  -o-box-shadow: 4px 4px 0px 0px #3D3D3D;
  box-shadow: 4px 4px 0px 0px #3D3D3D;
}
.event__item-content .title {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.4;
}
.event__item-content .title a {
  display: inline;
  background-image: linear-gradient(var(--tg-heading-color), var(--tg-heading-color)), linear-gradient(var(--tg-heading-color), var(--tg-heading-color));
  background-size: 0% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.event__item-content .title a:hover {
  color: inherit;
  background-size: 0 2px, 100% 2px;
}
.event__item-content .location {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--tg-body-color);
  gap: 5px;
  line-height: 1;
}
.event__item-content .location i {
  font-size: 18px;
  line-height: 0;
  color: var(--tg-theme-primary);
}
.event__item-content .location:hover {
  color: var(--tg-theme-secondary);
}
.event__item-wrap .row {
  --bs-gutter-x: 20px;
}
.event__shape img {
  position: absolute;
  right: 9%;
  bottom: 13%;
  z-index: -1;
}
.event__details-thumb {
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .event__details-thumb {
    margin-bottom: 30px;
  }
}
.event__details-thumb img {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  min-height: 300px;
  object-fit: cover;
}
.event__details-content .title {
  margin-bottom: 15px;
  font-size: 36px;
}
@media (max-width: 1500px) {
  .event__details-content .title {
    font-size: 32px;
  }
}
@media (max-width: 1199.98px) {
  .event__details-content .title {
    font-size: 30px;
  }
}
@media (max-width: 767.98px) {
  .event__details-content .title {
    font-size: 28px;
  }
}
.event__details-content .title-two {
  font-size: 24px;
  margin-bottom: 15px;
}
.event__details-content p {
  margin-bottom: 0;
}
.event__details-content-wrap .row .col-70 {
  width: 70%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .event__details-content-wrap .row .col-70 {
    width: 65%;
  }
}
@media (max-width: 991.98px) {
  .event__details-content-wrap .row .col-70 {
    width: 100%;
  }
}
.event__details-content-wrap .row .col-30 {
  width: 30%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .event__details-content-wrap .row .col-30 {
    width: 35%;
  }
}
@media (max-width: 991.98px) {
  .event__details-content-wrap .row .col-30 {
    width: 100%;
  }
}
.event__details-content-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}
.event__details-content-top .tag {
  font-size: 13px;
  font-weight: 500;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  display: block;
  padding: 8px 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  line-height: 1;
}
.event__details-content-top .tag:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-heading-color);
}
.event__details-content-top .avg-rating {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 5px;
  color: var(--tg-common-color-gray-3);
}
.event__details-content-top .avg-rating i {
  color: var(--tg-common-color-yellow);
  line-height: 1;
}
.event__details-overview {
  margin-bottom: 20px;
}
.event__details-inner {
  margin: 25px 0 20px;
}
.event__details-inner .row .col-39 {
  width: 38.8%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .event__details-inner .row .col-39 {
    width: 100%;
  }
}
.event__details-inner .row .col-61 {
  width: 61.2%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .event__details-inner .row .col-61 {
    width: 100%;
  }
}
.event__details-inner img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .event__details-inner img {
    margin-bottom: 30px;
  }
}
.event__details-inner-content .title {
  margin-bottom: 25px;
  font-size: 24px;
  text-transform: capitalize;
}
.event__details-inner-content .about__info-list-item {
  margin: 0 0 10px;
}
.event__details-inner-content .about__info-list-item:last-child {
  margin: 0;
}
.event__details-inner-content .about__info-list-item i {
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
}
.event__details-inner-content .about__info-list-item p {
  font-size: 16px;
}
.event__meta .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px 35px;
  flex-wrap: wrap;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 25px;
  margin-bottom: 28px;
}
.event__meta .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  color: var(--tg-common-color-gray-3);
}
.event__meta .list-wrap li::before {
  content: "";
  position: absolute;
  right: -19px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background: #8C9AB4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.event__meta .list-wrap li:last-child::before {
  display: none;
}
.event__meta .list-wrap li i {
  font-size: 20px;
  line-height: 0;
}
.event__meta .list-wrap li.author img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.event__meta .list-wrap li.author a {
  color: var(--tg-common-color-dark);
}
.event__meta .list-wrap li.author a:hover {
  color: var(--tg-theme-primary);
}
.event__meta .list-wrap li.location i {
  color: var(--tg-theme-primary);
}
.event__sidebar {
  margin: -190px 50px 0 20px;
}
@media (max-width: 1199.98px) {
  .event__sidebar {
    margin: -190px 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .event__sidebar {
    margin: 80px 0 0 0;
  }
}
.event__sidebar .courses__details-sidebar {
  margin: 0;
}
.event__widget {
  margin-bottom: 30px;
}
.event__widget:last-child {
  margin-bottom: 0;
}
.event__map {
  background: var(--tg-common-color-white);
  border: 1px solid #DFDFDF;
  -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 30px;
}
.event__map .title {
  font-size: 20px;
  margin-bottom: 20px;
}
.event__map .map {
  width: 100%;
  height: 240px;
}
.event__map .map iframe {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}

/*=============================
    18. FAQ
===============================*/
.faq__area {
  background: var(--tg-common-color-gray);
  padding: 260px 0 120px;
  margin-top: -140px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .faq__area {
    padding: 240px 0 100px;
  }
}
.faq__area::before {
  content: "";
  position: absolute;
  left: 45px;
  bottom: 20%;
  width: 500px;
  height: 500px;
  background: #E9E2F9;
  filter: blur(200px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
}
.faq__area::after {
  content: "";
  position: absolute;
  right: 54px;
  top: 110px;
  width: 500px;
  height: 500px;
  background: #E9E2F9;
  filter: blur(200px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
}
.faq__img {
  position: relative;
  z-index: 1;
  width: 450px;
  height: 562px;
  border-radius: 1000px;
  background: var(--tg-common-color-white);
  box-shadow: 9px 8px 0px #171717;
  margin: 0 auto;
  overflow: hidden;
}
@media (max-width: 1199.98px) {
  .faq__img {
    width: 400px;
    height: 550px;
  }
}
@media (max-width: 767.98px) {
  .faq__img {
    width: 100%;
    height: 500px;
    max-width: 400px;
  }
}
.faq__img img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.faq__img .shape-one {
  position: absolute;
  left: 27%;
  top: 11%;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .faq__img .shape-one {
    left: 25%;
    top: 9%;
  }
}
@media (max-width: 767.98px) {
  .faq__img .shape-one {
    left: 16%;
    top: 11%;
  }
}
.faq__img .shape-one svg {
  color: var(--tg-common-color-yellow-2);
}
.faq__img .shape-two {
  width: 61px;
  height: 46px;
  position: absolute;
  right: 25%;
  top: 10%;
}
@media (max-width: 1199.98px) {
  .faq__img .shape-two {
    right: 18%;
  }
}
.faq__img .shape-two .svg-icon {
  width: 100%;
  height: 100%;
  display: block;
}
.faq__img .shape-two .svg-icon path {
  stroke: var(--tg-common-color-black);
}
.faq__img-two {
  background: #F3F3F3;
  box-shadow: 9px 8px 0px #B8B8B8;
}
.faq__img-wrap {
  position: relative;
  margin-top: 45px;
}
@media (max-width: 991.98px) {
  .faq__img-wrap {
    margin-bottom: 50px;
  }
}
@media (max-width: 991.98px) {
  .faq__img-wrap {
    margin-top: 0;
  }
}
.faq__content {
  width: 80%;
}
@media (max-width: 1199.98px) {
  .faq__content {
    width: 100%;
  }
}
.faq__content p {
  margin-bottom: 10px;
}
.faq__wrap .accordion-item {
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.faq__wrap .accordion-item .accordion-button {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--tg-common-color-gray-5);
  padding: 22px 0;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--tg-heading-font-family);
  border-radius: 0;
  color: var(--tg-body-color);
  position: relative;
}
@media (max-width: 767.98px) {
  .faq__wrap .accordion-item .accordion-button {
    padding: 22px 30px 22px 0;
  }
}
.faq__wrap .accordion-item .accordion-button::after {
  content: "\f105";
  background-image: none;
  font-family: "flaticon_skill_grow";
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 25px;
  color: var(--tg-common-color-dark);
  box-shadow: none;
  background: transparent;
  font-weight: 700;
}
.faq__wrap .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: var(--tg-theme-primary);
}
.faq__wrap .accordion-item .accordion-button:not(.collapsed)::after {
  transform: rotate(-90deg);
  color: var(--tg-theme-primary);
}
.faq__wrap .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.faq__wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.faq__wrap .accordion-body {
  padding: 15px 0 0;
}
.faq__wrap .accordion-body p {
  margin-bottom: 0;
  color: var(--tg-common-color-dark);
}

.curved-circle {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--tg-common-color-dark);
  right: 50%;
  transform: translateX(-50%);
  top: -8%;
  letter-spacing: 5px;
}
@media (max-width: 1199.98px) {
  .curved-circle {
    font-size: 15px;
    top: -6%;
    letter-spacing: 3px;
  }
}
@media (max-width: 767.98px) {
  .curved-circle {
    display: none;
  }
}

/*=============================
    19. Shop
===============================*/
.shop-top-wrap {
  margin-bottom: 30px;
}
.shop-top-left p {
  margin-bottom: 0;
  font-size: 18px;
}
@media (max-width: 767.98px) {
  .shop-top-left p {
    margin-bottom: 15px;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-left p {
    margin-bottom: 0;
    text-align: left;
  }
}
.shop-top-right {
  position: relative;
  width: 190px;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .shop-top-right {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-right {
    margin: 0 0 0 auto;
  }
}
.shop-top-right select {
  background-color: var(--tg-common-color-gray);
  border: none;
  color: var(--tg-body-color);
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 4px;
  outline: none;
  padding: 12px 37px 9px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  line-height: 1.2;
  height: 40px;
}
.shop-top-right::after {
  content: "\f078";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  right: 15px;
  font-size: 14px;
  color: var(--tg-theme-primary);
}
.shop-item {
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  padding: 25px 25px 30px;
  margin-bottom: 30px;
  background: var(--tg-common-color-white);
}
@media (max-width: 1199.98px) {
  .shop-item {
    padding: 20px 20px 25px;
  }
}
@media (max-width: 991.98px) {
  .shop-item {
    padding: 25px 25px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-item {
    padding: 20px 20px 25px;
  }
}
.shop-item:hover .shop-thumb img {
  opacity: 0.8;
}
.shop-item:hover .shop-action li {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.shop-thumb {
  position: relative;
  text-align: center;
  margin-bottom: 25px;
}
.shop-thumb img {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}
.shop-thumb .flash-sale {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
  padding: 6px 13px;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  z-index: 1;
}
.shop-thumb .flash-sale.hot {
  background: var(--tg-theme-secondary);
  color: var(--tg-heading-color);
}
.shop-action {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  gap: 10px;
}
.shop-action li {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: visibility 0.3s ease-out 0.1s, opacity 0.3s ease-out 0.15s, transform 0.3s ease-out 0.1s;
}
.shop-action li:nth-child(2) {
  transition-delay: 0.15s;
}
.shop-action li:nth-child(3) {
  transition-delay: 0.2s;
}
.shop-action a {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-common-color-white);
  color: var(--tg-theme-primary);
  font-size: 15px;
  line-height: 0;
}
.shop-action a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.shop-content {
  text-align: center;
}
.shop-content .price {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  color: var(--tg-theme-primary);
}
.shop-content .price del {
  color: var(--tg-body-color);
  margin-left: 5px;
  opacity: 0.7;
}
.shop-content .title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
}
.shop-content .title a:hover {
  color: var(--tg-theme-primary);
}
.shop-content .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #F8BC24;
  gap: 3px;
  line-height: 1;
  margin: 0 0 10px;
}
.shop-content .rating .avg {
  color: var(--tg-body-color);
}
@media (max-width: 991.98px) {
  .shop-sidebar {
    margin-top: 80px;
  }
}
.shop-widget {
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  padding: 30px 30px;
  background: #F7F7FA;
  margin-bottom: 30px;
}
.shop-widget:last-child {
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .shop-widget {
    padding: 25px 20px;
  }
}
@media (max-width: 991.98px) {
  .shop-widget {
    padding: 25px 30px;
  }
}
.shop-widget ul li {
  margin-bottom: 15px;
}
.shop-widget ul li:last-child {
  margin-bottom: 0;
}
.shop-widget .form-check {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
  padding: 0;
  min-height: inherit;
}
.shop-widget .form-check-input {
  border-radius: 4px;
  border: 1px solid #B2BDCD;
  margin: 0 10px 0 0;
  width: 16px;
  height: 16px;
}
.shop-widget .form-check-input:focus {
  box-shadow: none;
  border-color: var(--tg-theme-primary);
}
.shop-widget .form-check-label {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  color: var(--tg-body-color);
  user-select: none;
}
.shop-widget .form-check .rating {
  display: flex;
  align-items: center;
}
.shop-widget .form-check .rating ul {
  display: flex;
  align-items: center;
  line-height: 1;
  color: #F8BC24;
  gap: 4px;
}
.shop-widget .form-check .rating ul li {
  margin-bottom: 0;
}
.shop-widget .form-check .rating ul li.delete {
  color: #D7D7D7;
}
.shop-widget .form-check .rating span {
  color: #5A7093;
  margin-left: 5px;
}
@media (max-width: 1500px) {
  .shop-details-images-wrap {
    flex-direction: column-reverse;
  }
}
.shop-details-images-wrap .nav-tabs {
  border-bottom: none;
  gap: 15px;
  margin: 0 0;
  width: 119px;
  flex: 0 0 119px;
  flex-direction: column;
}
@media (max-width: 1500px) {
  .shop-details-images-wrap .nav-tabs {
    width: 100%;
    flex: 0 0 auto;
    flex-direction: row;
  }
}
.shop-details-images-wrap .nav-item {
  width: 120px;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .shop-details-images-wrap .nav-item {
    width: auto;
    flex: 1 0 0%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-details-images-wrap .nav-item {
    width: 120px;
    flex: 0 0 auto;
  }
}
.shop-details-images-wrap .nav-link {
  margin-bottom: 0;
  background: transparent;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  padding: 0 0;
  overflow: hidden;
  opacity: 0.5;
}
.shop-details-images-wrap .nav-link.active {
  border-color: var(--tg-theme-primary);
  opacity: 1;
}
.shop-details-images-wrap .tab-content img {
  border-radius: 6px;
  border: 1px solid #E4E4E4;
}
@media (max-width: 767.98px) {
  .shop-details-images-wrap .tab-content img {
    width: 100%;
  }
}
.shop-details-content {
  margin-left: 25px;
}
@media (max-width: 1199.98px) {
  .shop-details-content {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .shop-details-content {
    margin: 60px 0 0;
  }
}
.shop-details-content > .title {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 12px;
}
@media (max-width: 767.98px) {
  .shop-details-content > .title {
    font-size: 26px;
  }
}
.shop-details-content .product-review {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}
@media (max-width: 767.98px) {
  .shop-details-content .product-review {
    margin-bottom: 15px;
  }
}
.shop-details-content .product-review .rating {
  display: flex;
  align-items: center;
  color: #F8BC24;
  gap: 3px;
}
.shop-details-content .product-review span {
  color: #5A7093;
}
.shop-details-content .price {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  color: var(--tg-theme-primary);
}
.shop-details-content p {
  margin-bottom: 35px;
}
@media (max-width: 767.98px) {
  .shop-details-content p {
    margin-bottom: 30px;
  }
}
.shop-details-qty {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 40px;
}
.shop-details-qty .title {
  font-weight: 500;
  font-size: 18px;
  color: var(--tg-theme-primary);
  display: block;
}
.shop-details-qty .wishlist-btn {
  background: #F6F7FA;
  border: 1px solid #E4E4E4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.shop-details-qty .wishlist-btn:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  border-color: var(--tg-theme-primary);
}
.shop-details-bottom .list-wrap li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}
.shop-details-bottom .list-wrap li:last-child {
  margin-bottom: 0;
}
.shop-details-bottom .list-wrap li .title {
  font-weight: 500;
  font-size: 16px;
  display: block;
  color: var(--tg-heading-color);
}
.shop-details-bottom .list-wrap li .code, .shop-details-bottom .list-wrap li a {
  color: var(--tg-body-color);
}
.shop-details-bottom .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}

.cart-plus-minus {
  position: relative;
  width: 116px;
  flex: 0 0 auto;
}
.cart-plus-minus input {
  width: 100%;
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  height: 50px;
  text-align: center;
  padding: 0 30px;
  font-weight: 500;
  font-size: 16px;
  color: var(--tg-heading-color);
}
.cart-plus-minus .qtybutton {
  position: absolute;
  top: 0;
  left: 0;
  width: 31px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #838383;
  cursor: pointer;
  border-right: 1px solid #D7D7D7;
  user-select: none;
  -moz-user-select: none;
  line-height: 0;
}
.cart-plus-minus .qtybutton.inc {
  left: auto;
  right: 0;
  border-right: none;
  border-left: 1px solid #D7D7D7;
}
.cart-plus-minus .qtybutton.dec {
  font-size: 26px;
}

.product-desc-wrap {
  margin-top: 100px;
}
.product-desc-wrap .tab-content p {
  margin-bottom: 0;
}
.product-desc-wrap .nav-tabs {
  border-bottom: 2px solid #E6EAEF;
  gap: 30px;
  margin-bottom: 25px;
}
.product-desc-wrap .nav-tabs .nav-link {
  margin-bottom: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0 5px 10px;
  font-weight: 500;
  font-size: 19px;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-body-color);
  position: relative;
}
.product-desc-wrap .nav-tabs .nav-link.active {
  color: var(--tg-theme-primary);
}
.product-desc-wrap .nav-tabs .nav-link.active::before {
  opacity: 1;
}
.product-desc-wrap .nav-tabs .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: var(--tg-theme-primary);
  transition: 0.3s linear;
  opacity: 0;
}
.product-desc-review {
  padding: 25px 30px;
  border: 1px solid #E6EAEF;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.product-desc-review .left-rc {
  margin-bottom: 10px;
}

.related-product-area {
  position: relative;
  margin-top: 120px;
}
@media (max-width: 767.98px) {
  .related-product-area {
    margin-top: 90px;
  }
}
.related-product-area .shop-thumb img {
  margin: 0 auto;
}

.shop-active [class*=col-] {
  padding: 0 15px;
}
.shop-active .slick-arrow {
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--tg-common-color-gray);
  color: var(--tg-theme-primary);
  border: 1px solid var(--tg-common-color-white);
  box-shadow: 0px 10px 20px #DFDFDF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.shop-active .slick-arrow:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.shop-active .slick-arrow.slick-next {
  left: auto;
  right: -10px;
}

/*=============================
    20. Blog
===============================*/
.blog__post-area {
  position: relative;
  padding: 240px 0 90px;
  background: var(--tg-common-color-gray);
  z-index: 1;
  margin-top: -150px;
}
@media (max-width: 767.98px) {
  .blog__post-area {
    padding: 220px 0 70px;
  }
}
.blog__post-area::before {
  content: "";
  position: absolute;
  left: 50px;
  top: 50px;
  width: 473px;
  height: 473px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #E9E2F9;
  filter: blur(200px);
  z-index: -1;
}
.blog__post-area::after {
  content: "";
  position: absolute;
  right: 10%;
  bottom: 20px;
  width: 473px;
  height: 473px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #E9E2F9;
  filter: blur(200px);
  z-index: -1;
}
.blog__post-area-two {
  padding: 120px 0 90px;
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .blog__post-area-two {
    padding: 100px 0 70px;
  }
}
.blog__post-item {
  border: 1px solid #B5B5C3;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  background: var(--tg-common-color-white);
  padding: 20px 25px 35px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  margin-bottom: 30px;
}
.blog__post-item:hover {
  -webkit-box-shadow: 10px 10px 0px 0px #CAC9D6;
  -moz-box-shadow: 10px 10px 0px 0px #CAC9D6;
  -ms-box-shadow: 10px 10px 0px 0px #CAC9D6;
  -o-box-shadow: 10px 10px 0px 0px #CAC9D6;
  box-shadow: 10px 10px 0px 0px #CAC9D6;
}
.blog__post-thumb {
  margin-bottom: 22px;
  position: relative;
}
.blog__post-thumb img {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  width: 100%;
  height: 224px;
  object-fit: cover;
}
.blog__post-thumb .post-tag {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: var(--tg-common-color-white);
  background: var(--tg-theme-primary);
  position: absolute;
  left: 22px;
  top: 17px;
  padding: 9px 14px;
  border-radius: 30px;
  z-index: 1;
}
.blog__post-thumb .post-tag:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-heading-color);
}
.blog__post-content .title {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.blog__post-content .title a {
  display: inline;
  background-image: linear-gradient(var(--tg-heading-color), var(--tg-heading-color)), linear-gradient(var(--tg-heading-color), var(--tg-heading-color));
  background-size: 0% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.blog__post-content .title a:hover {
  color: inherit;
  background-size: 0 2px, 100% 2px;
}
.blog__post-meta {
  margin-bottom: 20px;
}
.blog__post-meta .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.blog__post-meta .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
.blog__post-meta .list-wrap li i {
  font-size: 20px;
  color: var(--tg-theme-primary);
  margin-right: 8px;
}
.blog__post-meta .list-wrap li a {
  color: var(--tg-body-color);
  margin-left: 5px;
}
.blog__post-meta .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.blog-sidebar {
  margin-left: 15px;
}
@media (max-width: 1199.98px) {
  .blog-sidebar {
    margin-left: 0px;
  }
}
@media (max-width: 991.98px) {
  .blog-sidebar {
    margin-top: 80px;
  }
}
.blog-widget {
  background: var(--tg-common-color-gray);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 30px;
  margin-bottom: 30px;
}
.blog-widget:last-child {
  margin-bottom: 0;
}
.blog-widget .shop-cat-list .list-wrap li {
  line-height: 1;
  margin-bottom: 20px;
}
.blog-widget .shop-cat-list .list-wrap li:last-child {
  margin-bottom: 0;
}
.blog-widget .shop-cat-list .list-wrap li a {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  font-size: 16px;
  color: var(--tg-body-color);
  gap: 5px;
}
.blog-widget .shop-cat-list .list-wrap li a i {
  color: var(--tg-theme-primary);
  font-size: 20px;
  line-height: 0;
}
.blog-widget .shop-cat-list .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.blog-widget .tagcloud {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
}
.blog-widget .tagcloud a {
  font-size: 16px;
  color: var(--tg-theme-primary);
  display: block;
  background: var(--tg-common-color-white);
  padding: 10px 15px;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.blog-widget .tagcloud a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}
.blog__details-thumb {
  margin-bottom: 30px;
}
.blog__details-thumb img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  min-height: 300px;
  object-fit: cover;
}
.blog__details-content .title {
  margin-bottom: 10px;
  font-size: 36px;
}
@media (max-width: 1500px) {
  .blog__details-content .title {
    font-size: 32px;
  }
}
@media (max-width: 1199.98px) {
  .blog__details-content .title {
    font-size: 30px;
  }
}
@media (max-width: 767.98px) {
  .blog__details-content .title {
    font-size: 26px;
  }
}
.blog__details-content > p {
  margin-bottom: 10px;
}
.blog__details-content .inner-title {
  margin-bottom: 10px;
  font-size: 30px;
}
@media (max-width: 767.98px) {
  .blog__details-content .inner-title {
    font-size: 24px;
  }
}
.blog__details-content-inner {
  margin: 30px 0 25px;
}
.blog__details-content-inner > p {
  margin-bottom: 20px;
}
.blog__details-bottom {
  border-top: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  padding: 20px 0;
  margin: 40px 0 30px;
}
.blog__details-bottom .tg-post-tag {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.blog__details-bottom .tg-post-tag .tag-title {
  margin-bottom: 0;
  font-size: 17px;
}
.blog__details-bottom .tg-post-tag .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
}
.blog__details-bottom .tg-post-tag .list-wrap li a {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  display: block;
  font-size: 15px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  padding: 10px 21px;
  line-height: 1.2;
}
.blog__details-bottom .tg-post-tag .list-wrap li a:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-heading-color);
}
.blog__details-bottom .tg-post-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 767.98px) {
  .blog__details-bottom .tg-post-social {
    margin-top: 15px;
  }
}
.blog__details-bottom .tg-post-social .social-title {
  margin-bottom: 0;
  font-size: 17px;
}
.blog__details-bottom .tg-post-social .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog__details-bottom .tg-post-social .list-wrap li a {
  font-size: 20px;
  color: var(--tg-body-color);
}
.blog__details-bottom .tg-post-social .list-wrap li a:hover {
  color: var(--tg-theme-secondary);
}
.blog__post-author {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 41px 45px;
  background: #F7F7FA;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  gap: 30px;
  margin-bottom: 40px;
}
@media (max-width: 1199.98px) {
  .blog__post-author {
    padding: 30px 25px;
  }
}
@media (max-width: 767.98px) {
  .blog__post-author {
    flex-wrap: wrap;
    gap: 25px;
  }
}
.blog__post-author-thumb {
  width: 115px;
  flex: 0 0 auto;
}
.blog__post-author-thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.blog__post-author-content .designation {
  display: block;
  line-height: 1;
  color: var(--tg-common-color-dark);
  margin-bottom: 5px;
}
.blog__post-author-content .name {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 500;
}
.blog__post-author-content p {
  margin-bottom: 0;
}

.widget_search {
  padding: 0 0;
}
.widget-title {
  margin-bottom: 20px;
  font-size: 20px;
}

.sidebar-search-form form {
  position: relative;
}
.sidebar-search-form input {
  background: var(--tg-common-color-gray);
  width: 100%;
  border: none;
  padding: 15px 45px 15px 20px;
  color: var(--tg-body-color);
  font-size: 16px;
  line-height: 1;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}
.sidebar-search-form input::placeholder {
  color: var(--tg-body-color);
  font-size: 16px;
  opacity: 0.8;
}
.sidebar-search-form button {
  border: none;
  padding: 0;
  background: transparent;
  font-size: 22px;
  line-height: 0;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.rc-post-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.rc-post-item:last-child {
  margin-bottom: 0;
}
.rc-post-thumb {
  width: 74px;
  flex: 0 0 auto;
}
.rc-post-thumb img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.rc-post-content .date {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
  font-size: 13px;
  font-weight: 500;
  color: var(--tg-common-color-gray-3);
  margin-bottom: 12px;
}
.rc-post-content .date i {
  color: var(--tg-theme-primary);
  font-size: 14px;
}
.rc-post-content .title {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
}

blockquote {
  background: #EFEEFE;
  margin: 40px 90px;
  padding: 35px 120px 35px 45px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1500px) {
  blockquote {
    margin: 40px 50px;
  }
}
@media (max-width: 1199.98px) {
  blockquote {
    margin: 40px 20px;
    padding: 35px 100px 35px 30px;
  }
}
@media (max-width: 767.98px) {
  blockquote {
    margin: 40px 0;
    padding: 30px 30px 30px 30px;
  }
}
blockquote::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: var(--tg-theme-primary);
}
blockquote::after {
  content: "\f10e";
  position: absolute;
  right: 30px;
  top: 22px;
  line-height: 1;
  font-size: 62px;
  color: #CECCFD;
  font-family: var(--tg-icon-font-family);
  font-weight: 700;
  z-index: -1;
}
@media (max-width: 767.98px) {
  blockquote::after {
    right: 17px;
    top: 17px;
    font-size: 40px;
  }
}
blockquote > p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  color: var(--tg-common-color-dark);
  font-family: var(--tg-heading-font-family);
}
@media (max-width: 1199.98px) {
  blockquote > p {
    font-size: 18px;
  }
}

.comment-wrap-title .title {
  margin-bottom: 50px;
  font-size: 24px;
}
.comment-respond {
  background: #F7F7FA;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  padding: 32px 45px 50px;
}
@media (max-width: 1199.98px) {
  .comment-respond {
    padding: 32px 25px 50px;
  }
}
.comment-reply-title {
  margin-bottom: 5px;
  font-size: 30px;
  text-transform: capitalize;
}
@media (max-width: 1199.98px) {
  .comment-reply-title {
    font-size: 26px;
  }
}
.comment-form .row {
  --bs-gutter-x: 20px;
}
.comment-form .row [class*=col-] {
  padding: 0 10px;
}
.comment-notes {
  margin-bottom: 15px;
}
.comment-field {
  margin-bottom: 15px;
}
.comment-field input, .comment-field textarea {
  width: 100%;
  display: block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: var(--tg-common-color-white);
  border: 1px solid #E1E4E7;
  font-size: 16px;
  color: var(--tg-body-color);
  padding: 15px 15px;
  line-height: 1.2;
  height: 50px;
}
.comment-field input::placeholder, .comment-field textarea::placeholder {
  opacity: 0.8;
  font-size: 16px;
  color: var(--tg-body-color);
}
.comment-field textarea {
  min-height: 130px;
  max-height: 130px;
}
.comment-field.checkbox-grp {
  display: flex;
  align-items: center;
  margin: 15px 0 32px;
}
.comment-field.checkbox-grp input {
  width: 22px;
  margin-right: 9px;
  height: 22px;
  cursor: pointer;
}
.comment-field.checkbox-grp label {
  font-weight: 400;
  font-size: 16px;
  color: var(--tg-body-color);
  user-select: none;
}

.latest-comments .list-wrap li {
  padding-bottom: 30px;
  margin-bottom: 45px;
  border-bottom: 1px solid #E8E8E8;
}
.latest-comments .list-wrap li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.latest-comments .list-wrap li:last-child .comments-box {
  margin-left: 45px;
}
@media (max-width: 767.98px) {
  .latest-comments .list-wrap li:last-child .comments-box {
    margin-left: 0;
  }
}
.latest-comments .comments-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 25px;
}
@media (max-width: 767.98px) {
  .latest-comments .comments-box {
    gap: 20px;
    flex-wrap: wrap;
  }
}
.latest-comments .comments-avatar {
  flex: 0 0 auto;
  width: 115px;
}
.latest-comments .comments-avatar img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.latest-comments .comments-text .avatar-name {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.latest-comments .comments-text .avatar-name .name {
  font-size: 20px;
  margin-bottom: 0;
}
.latest-comments .comments-text .avatar-name .date {
  font-size: 14px;
  margin-left: auto;
  font-weight: 500;
}
.latest-comments .comments-text p {
  margin-bottom: 15px;
}
.latest-comments .comments-text .comment-reply .comment-reply-link {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background: #F7F7FB;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  line-height: 1;
  padding: 10px 18px;
}
.latest-comments .comments-text .comment-reply .comment-reply-link:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
}

.pagination__wrap ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 10px;
}
.pagination__wrap ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #E6E9EF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 18px;
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-medium);
}
.pagination__wrap ul li a:hover {
  color: var(--tg-common-color-white);
  background: var(--tg-theme-primary);
}
.pagination__wrap ul li.active a {
  color: var(--tg-common-color-white);
  background: var(--tg-theme-primary);
}

/*=============================
    21. Newsletter
===============================*/
.newsletter__area {
  background: var(--tg-theme-primary);
  position: relative;
  padding: 35px 0 0;
  z-index: 1;
  overflow: hidden;
  margin-top: 20px;
}
@media (max-width: 991.98px) {
  .newsletter__area {
    padding: 40px 0;
  }
}
.newsletter__img-wrap {
  position: relative;
  text-align: center;
}
@media (max-width: 991.98px) {
  .newsletter__img-wrap {
    display: none;
  }
}
.newsletter__img-wrap img:not(:nth-child(1)) {
  position: absolute;
}
.newsletter__img-wrap img:nth-child(2) {
  left: 5%;
  bottom: 0;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .newsletter__img-wrap img:nth-child(2) {
    left: 15%;
  }
}
.newsletter__img-wrap img:nth-child(3) {
  right: 16%;
  top: 18%;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .newsletter__img-wrap img:nth-child(3) {
    right: 5%;
  }
}
@media (max-width: 991.98px) {
  .newsletter__content {
    text-align: center;
  }
}
.newsletter__content .title {
  margin-bottom: 25px;
  color: var(--tg-common-color-white);
  font-size: 36px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.2;
}
@media (max-width: 1199.98px) {
  .newsletter__content .title {
    font-size: 34px;
  }
}
.newsletter__content .title span {
  font-weight: 700;
}
.newsletter__form {
  width: 80%;
}
@media (max-width: 1199.98px) {
  .newsletter__form {
    width: 100%;
  }
}
.newsletter__form form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 767.98px) {
  .newsletter__form form {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.newsletter__form form input {
  width: 100%;
  border: 1px solid #2C69C2;
  background: #2C69C2;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  color: var(--tg-common-color-white);
  font-size: 16px;
  padding: 8px 20px;
  line-height: 1.4;
  height: -10px;
  flex-grow: 1;
}
.newsletter__form form input::placeholder {
  color: #9E9BF1;
  font-size: 16px;
  text-transform: capitalize;
}
.newsletter__form form .btn {
  background: var( --tg-common-color-yellow);
  /* background: var(--tg-theme-secondary); */
  min-width: 198px;
  border: 1px solid #141109;
  padding: 10px 25px;
  color: var(--tg-heading-color);
  box-shadow: -3px 6px 1px 0px #3D3D3D !important;
}
.newsletter__form form .btn:hover {
  box-shadow: none;
}
.newsletter__shape img {
  position: absolute;
  right: 12%;
  top: 0;
  z-index: -1;
}
@media (max-width: 1500px) {
  .newsletter__shape img {
    right: 0;
  }
}
@media (max-width: 1199.98px) {
  .newsletter__shape img {
    display: none;
  }
}

/*=============================
    15. Work
===============================*/
.work__images {
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 991.98px) {
  .work__images {
    margin-bottom: 50px;
  }
}
.work__images .shape {
  right: 220px;
}
@media (max-width: 767.98px) {
  .work__images .shape {
    right: 88px;
  }
}
.work__content {
  width: 85%;
}
@media (max-width: 1199.98px) {
  .work__content {
    width: 100%;
  }
}
.work__content > p {
  margin-bottom: 25px;
}
.work__list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 -15px;
}
.work__list-box {
  width: 50%;
  flex: 0 0 auto;
  padding: 0 15px;
  margin-bottom: 30px;
}
.work__list-box-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 18px;
}
@media (max-width: 1199.98px) {
  .work__list-box-top {
    display: block;
  }
}
.work__list-box-top .title {
  font-size: 20px;
  margin-bottom: 0;
}
.work__list-box .icon {
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #FF6666;
  border: 1px solid #CE3B3B;
  font-size: 35px;
  line-height: 0;
  color: var(--tg-common-color-white);
  -webkit-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.25);
  -ms-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 1199.98px) {
  .work__list-box .icon {
    margin-bottom: 20px;
  }
}
.work__list-box p {
  margin-bottom: 0;
}
.work__list-box:nth-child(2) .icon {
  background: #1BCBE3;
  border: 1px solid #019AAF;
}

/*=============================
    22. Contact
===============================*/
.contact-info-wrap .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 40px 40px;
  margin-bottom: 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
  background: #F7F7FA;
  gap: 25px;
}
@media (max-width: 1199.98px) {
  .contact-info-wrap .list-wrap li {
    padding: 25px 25px;
    gap: 15px;
    flex-wrap: wrap;
  }
}
@media (max-width: 991.98px) {
  .contact-info-wrap .list-wrap li {
    flex-wrap: nowrap;
  }
}
@media (max-width: 767.98px) {
  .contact-info-wrap .list-wrap li {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767.98px) {
  .contact-info-wrap .list-wrap li {
    flex-direction: row;
    align-items: center;
  }
}
.contact-info-wrap .list-wrap li:last-child {
  margin-bottom: 0;
}
.contact-info-wrap .list-wrap li .icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-white);
  font-size: 20px;
  line-height: 0;
  flex: 0 0 auto;
}
.contact-info-wrap .list-wrap li .content .title {
  font-size: 20px;
  margin-bottom: 5px;
}
.contact-info-wrap .list-wrap li .content p {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.4;
}
.contact-info-wrap .list-wrap li .content a {
  display: block;
  font-weight: 500;
  color: var(--tg-body-color);
  line-height: 1.4;
}
.contact-info-wrap .list-wrap li .content a:hover {
  color: var(--tg-theme-primary);
}
.contact-form-wrap {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  border: 1px solid #E1E1E1;
  background: #F7F7FA;
  padding: 30px 40px 42px;
}
@media (max-width: 1199.98px) {
  .contact-form-wrap {
    padding: 30px 25px 40px;
  }
}
@media (max-width: 991.98px) {
  .contact-form-wrap {
    padding: 30px 30px 40px;
    margin-top: 60px;
  }
}
@media (max-width: 767.98px) {
  .contact-form-wrap {
    padding: 25px 20px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-form-wrap {
    padding: 30px 25px 40px;
  }
}
.contact-form-wrap .title {
  margin-bottom: 5px;
  position: relative;
  font-size: 30px;
}
@media (max-width: 1199.98px) {
  .contact-form-wrap .title {
    font-size: 28px;
  }
}
@media (max-width: 767.98px) {
  .contact-form-wrap .title {
    font-size: 24px;
  }
}
.contact-form-wrap p {
  margin-bottom: 15px;
}
.contact-form-wrap .form-grp {
  margin-bottom: 20px;
}
.contact-form-wrap .form-grp input, .contact-form-wrap .form-grp textarea {
  width: 100%;
  border: 1px solid #E1E4E7;
  border-radius: 5px;
  display: block;
  background: var(--tg-common-color-white);
  font-weight: 400;
  font-size: 16px;
  color: var(--tg-body-color);
  padding: 11px 20px;
  height: 50px;
  transition: 0.3s;
}
.contact-form-wrap .form-grp input::placeholder, .contact-form-wrap .form-grp textarea::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: var(--tg-body-color);
  opacity: 0.8;
}
.contact-form-wrap .form-grp input:focus, .contact-form-wrap .form-grp textarea:focus {
  border-color: var(--tg-theme-primary);
}
.contact-form-wrap .form-grp textarea {
  min-height: 230px;
  max-height: 230px;
}
.contact-form-wrap .row {
  --bs-gutter-x: 20px;
}
.contact-map {
  width: 100%;
  height: 555px;
  margin-top: 70px;
}
@media (max-width: 1199.98px) {
  .contact-map {
    height: 430px;
  }
}
@media (max-width: 767.98px) {
  .contact-map {
    height: 380px;
  }
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  filter: grayscale(1);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}

.ajax-response.error, .ajax-response.success {
  margin: 20px 0 0 !important;
}
.ajax-response.error {
  color: red;
}
.ajax-response.success {
  color: green;
}

/*=============================
    05. SignUp
===============================*/
.singUp-wrap {
  background: #F7F7FA;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  padding: 30px 50px 40px;
}
@media (max-width: 767.98px) {
  .singUp-wrap {
    padding: 30px 20px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .singUp-wrap {
    padding: 30px 30px 30px;
  }
}
.singUp-wrap .title {
  font-size: 36px;
  margin-bottom: 10px;
}
@media (max-width: 767.98px) {
  .singUp-wrap .title {
    font-size: 26px;
  }
}
.singUp-wrap p {
  margin-bottom: 25px;
}
@media (max-width: 767.98px) {
  .singUp-wrap p br {
    display: none;
  }
}

.account__social-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-common-color-white);
  border: 1px solid #E1E1E1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  font-size: 16px;
  gap: 10px;
  color: var(--tg-common-color-dark);
  padding: 10px 20px;
}
.account__social-btn:hover {
  color: var(--tg-theme-primary);
  border-color: var(--tg-theme-primary);
}
.account__divider {
  position: relative;
  text-align: center;
  padding-block-end: 35px;
  margin-block-start: 35px;
}
.account__divider::before {
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  top: 0;
  background: #E1E1E1;
  position: absolute;
}
.account__divider span {
  font-size: 16px;
  color: var(--tg-heading-color);
  padding: 20px;
  top: -35px;
  left: 45%;
  position: absolute;
  background: #F7F7FA;
}
.account__form .form-grp {
  margin-bottom: 20px;
}
.account__form .form-grp label {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}
.account__form .form-grp input {
  width: 100%;
  padding: 14px 20px;
  font-size: 16px;
  color: var(--tg-heading-color);
  border: 1px solid #E1E1E1;
  background: var(--tg-common-color-white);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.account__form .form-grp input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: var(--tg-body-color);
  opacity: 0.8;
  text-transform: capitalize;
}
.account__form .form-grp input:focus {
  border-color: var(--tg-theme-primary);
}
.account__form .btn {
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}
.account__switch {
  margin-top: 30px;
}
.account__switch p {
  margin-bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.account__switch p a {
  color: var(--tg-theme-primary);
  text-decoration: underline;
}
.account__switch p a:hover {
  color: var(--tg-theme-secondary);
}
.account__check {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
.account__check-remember {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 5px;
}
.account__check-remember input {
  width: 20px;
  height: 20px;
  margin-inline-end: 3px;
}
.account__check-remember input:focus {
  box-shadow: none;
}
.account__check-remember input:checked {
  background-color: var(--tg-theme-primary);
  border-color: var(--tg-theme-primary);
}
.account__check-remember label {
  user-select: none;
}
.account__check-forgot a {
  color: var(--tg-theme-primary);
  font-size: 16px;
  text-decoration: underline;
}
.account__check-forgot a:hover {
  color: var(--tg-theme-primary);
}

/*=============================
    23. 404 Error
===============================*/
.error-area {
  padding: 120px 0;
}
@media (max-width: 1500px) {
  .error-area {
    padding: 100px 0;
  }
}
.error-img {
  text-align: center;
  width: 648px;
  height: 323px;
  margin: 0 auto 65px;
}
@media (max-width: 1500px) {
  .error-img {
    margin-bottom: 45px;
  }
}
@media (max-width: 991.98px) {
  .error-img {
    margin-bottom: 40px;
  }
}
@media (max-width: 767.98px) {
  .error-img {
    margin-bottom: 30px;
    width: 100%;
    height: 100%;
  }
}
.error-img svg {
  width: 100%;
  height: 100%;
  color: var(--tg-theme-secondary);
}
.error-content .title {
  margin-bottom: 60px;
  font-weight: 600;
  font-size: 40px;
}
@media (max-width: 1199.98px) {
  .error-content .title {
    margin-bottom: 35px;
    font-size: 35px;
  }
}
@media (max-width: 767.98px) {
  .error-content .title {
    font-size: 30px;
    margin-bottom: 30px;
  }
}
.error-content .title span {
  display: block;
  margin-top: 10px;
}
@media (max-width: 767.98px) {
  .error-content .title span {
    margin-top: 5px;
  }
}
.error-content .tg-button-wrap {
  justify-content: center;
}

/*=============================
    24. Footer
===============================*/
.footer__area {
  background: var(--tg-common-color-black);
}
.footer__area-two {
  margin-top: -160px;
  background: var(--tg-common-color-black);
}
.footer__area-two .footer__top {
  padding: 230px 0 60px;
}
.footer__top {
  padding: 100px 0 60px;
}
@media (max-width: 767.98px) {
  .footer__top {
    padding: 80px 0 40px;
  }
}
.footer__top .row [class*=col-]:nth-child(2) .footer__widget {
  margin-left: 60px;
}
@media (max-width: 991.98px) {
  .footer__top .row [class*=col-]:nth-child(2) .footer__widget {
    margin-left: 0;
  }
}
.footer__top .row [class*=col-]:nth-child(3) .footer__widget {
  margin-left: 60px;
}
@media (max-width: 991.98px) {
  .footer__top .row [class*=col-]:nth-child(3) .footer__widget {
    margin-left: 0;
  }
}
.footer__widget {
  margin-bottom: 35px;
}
.footer__widget-title {
  margin-bottom: 0;
  color: var(--tg-common-color-white);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.footer__widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 4px;
  border-radius: 2px;
  background: var(--tg-theme-primary);
  background: #B3EC55;
}
.footer__content p {
  margin-bottom: 12px;
  color: var(--tg-common-color-gray-5);
  width: 100%;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer__content p {
    width: 100%;
  }
}
.footer__content .list-wrap li {
  color: var(--tg-common-color-gray-5);
  font-weight: 500;
  margin-bottom: 7px;
}
.footer__content .list-wrap li:last-child {
  margin-bottom: 0;
}
.footer__link .list-wrap li {
  margin-bottom: 10px;
}
.footer__link .list-wrap li:last-child {
  margin-bottom: 0;
}
.footer__link .list-wrap li a {
  font-size: 16px;
  font-weight: 500;
  color: var(--tg-common-color-gray-5);
  position: relative;
}
.footer__link .list-wrap li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform-origin: right top;
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer__link .list-wrap li a:hover {
  color: var(--tg-theme-secondary);
}
.footer__link .list-wrap li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer__contact-content p {
  margin-bottom: 10px;
  color: var(--tg-common-color-gray-5);
}
.footer__social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 35px;
}
.footer__social li a {
  color: var(--tg-common-color-gray-5);
}
.footer__social li a:hover {
  color: var(--tg-theme-secondary);
}
.footer__bottom {
  background: var(--tg-common-color-dark);
  padding: 35px 0;
}
@media (max-width: 991.98px) {
  .footer__bottom {
    padding: 25px 0;
  }
}
.footer__bottom-menu .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
}
@media (max-width: 767.98px) {
  .footer__bottom-menu .list-wrap {
    justify-content: center;
  }
}
.footer__bottom-menu .list-wrap li a {
  color: #8C9AB4;
  position: relative;
}
.footer__bottom-menu .list-wrap li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform-origin: right top;
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer__bottom-menu .list-wrap li a::after {
  content: "";
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 12px;
  background: #8C9AB4;
}
.footer__bottom-menu .list-wrap li a:hover {
  color: var(--tg-theme-secondary);
}
.footer__bottom-menu .list-wrap li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer__bottom-menu .list-wrap li:last-child a::after {
  display: none;
}

.app-download {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
}
.app-download a img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.copy-right-text p {
  margin-bottom: 0;
  color: #8C9AB4;
}
@media (max-width: 767.98px) {
  .copy-right-text p {
    margin-bottom: 5px;
    text-align: center;
  }
}

/*=============================
    00. Keyframe Animation
===============================*/
@-webkit-keyframes hoverShine {
  100% {
    left: 125%;
  }
}
@keyframes hoverShine {
  100% {
    left: 125%;
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 180s;
  animation-duration: 180s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.alltuchtopdown {
  -webkit-animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
  animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
  animation-duration: 3s;
}

@keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}
@-webkit-keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

/*# sourceMappingURL=main.css.map */
/* ----------------New Css-------------------- */
img.injectable {
  filter: invert(1);
}
h3.title.tg-svg.aos-init.aos-animate b {
  color: #3783f3;
}
section.banner-area.banner-bg.tg-motion-effects {
  background-image: url("../img/Images/bg-color.png");
}



.swiper {
  width: 100%;
  height: 100%;
}

/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.categories__wrap .swiper-button-next, .swiper-button-prev {
  background: #FFC224;
  border-radius: 50%;
  width: 35px !important;
  height: 35px !important;
  line-height: 35px;
  text-align: center;
  color: #000 !important;
  font-weight: 700;
  box-shadow: 1px 2px 9px #000;
}

.categories__wrap .swiper-button-prev:after, .categories__wrap .swiper-button-next:after {
  font-size: 12px !important;
}

.categories__wrap .swiper-slide.swiper-slide-active {
  padding-left: 60px;
}


/* .topSwiper .swiper-slide{
  margin-right: none;
} */
.worksmain {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}
.worksmain-lt {
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
}
.worksmain-in {
  flex: 1 1 auto;
}
span.woksshopimg img {
  padding: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
span.woksshopTxt {
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  color: #000;
}
.freeworkshp .title span:not(.svg-icon) > svg {
  position: absolute;
  left: 0;
  top: -5px;
  width: 100%;
  height: 59px;
  z-index: -1;
  color: #3783f3;
}


/* .courseDtls {
  display: flex;
  gap: 20px;
  row-gap: 20px;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
} */

.courseDtls {
  display: grid;
  grid-gap: 20px;  
  grid-template-columns: repeat( auto-fit, minmax(260px, 1fr) );
}



/* .coursedltsinn {
  padding: 10px;
  border: 1px solid #B5B5C3;
  border-radius: 10px;
} */
.coursedltsinn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd; /* Optional: adds a border to the grid items */
  padding: 15px; /* Optional: adds padding inside the grid items */
  background: #fff; /* Optional: sets a background color */
  height: 100%;
}
.courseimg {
  flex: 1;
}
.coursedltsinn .courseimg img {
  width: 100%;
}

.crsName {
  font-size: 17px;
  color: #000;
  font-weight: 600;
}
.crsDetails {
  display: flex;
  gap: 20px;
  justify-content: left;
  align-items: center;
}
.spnbrdr-rt{
  border-right: 2px solid #ddd;
    padding-right: 22px;
    display: inline-block;
}
.corseImages img {
  height: 90px;
  width: 90px;
  /* border-radius: 50%; */
  padding: 10px;
}
.section__title .titlegreen {
  display: inline-block;
  line-height: 1.62;
  /* background: var(--tg-common-color-gray-2); */
  background: var(--tg-common-color-lightgreen);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  padding: 3px 16px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-common-color-black-3);
  margin: 0 0 14px;
}
/* .breadcrumb{
  background-image: url("../img/bg/breadcrumb_bg.jpg");
  height: 20vh;
  width: 100%;
} */

.breadcrumbcustom {
  background-image: url("../img/bg/breadcrumb_bg.jpg");
  height: 20vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.languageDtls {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat( auto-fit, minmax(280px, 1fr) );
}
.languageDtlsinn {
  padding: 10px;
  border: 1px solid #B5B5C3;
  border-radius: 10px;
}
.languageimg img {
  height: 40px;
  border-radius: 5px;
  width: auto;
  margin-right: 8px;
}

span.langName {
  font-weight: 600;
  color: #000;
}
.languageDtlsinn:hover {
  box-shadow: 0px 2px 10px #ddd;
  cursor: pointer;
  background: #f1fdff;
}
.coursedltsinn:hover {
  box-shadow: 0px 8px 10px #ddd;
  cursor: pointer;
}
li.linkicon a {
  color: #fff;
}
li.linkicon a:hover {
  color: #fff;
}

.pageImageContainer{
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.pageImageContainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul.frenchiseul {
  list-style: none;
}
ul.frenchiseul h4 {
  font-size: 16px;
  margin-top: 20px;
}

.contact__details-area {
  padding: 60px 0;
}

.contact__form {
  padding-right: 15px;
}

.contact__form .form-group {
  margin-bottom: 15px;
}

.contact__form label {
  display: block;
  margin-bottom: 5px;
}

.contact__form input,
.contact__form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact__form button {
  background-color: #3783F3;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact__map {
  padding-left: 15px;
}

.map-container iframe {
  width: 100%;
  height: 400px;
  border: 0;
}
.popupClose {
  position: absolute;
  top: -12px;
  right: -13px;
  background: red;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}
.courseImg img {
  height: 100%;
  width: 100%;
}

.custome-gl._lightboxjs_y23g1_1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.mainlogo img {
  max-width: 230px !important;
}